import React from "react";
import {Card, Container} from "react-bootstrap";
import {SubjectBlock} from "./components/SubjectBlock";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {ClassMessage} from "./components/ClassMessage";
import {ExpirationChecker} from "../../components/ExpirationChecker";
import {ScheduleTeacher} from "./components/ScheduleTeacher";

interface Props {
    className: string
}

export const ClassPageTeacher: React.FC<Props> = (props: Props): JSX.Element => {
    return (
        <Container>
            <ExpirationChecker/>
            <TeacherNavbar/>
            <VerticalPlaceholder height="1.5em"/>
            <Card>
                <Card.Header>
                    <Card.Title>Klasse {props.className}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ScheduleTeacher className={props.className}/>
                    <SubjectBlock className={props.className}/>
                </Card.Body>
            </Card>

            <VerticalPlaceholder height="1.5em"/>

            <ClassMessage className={props.className}/>
        </Container>
    );
};
