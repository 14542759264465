import React, {useRef, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

interface Props {
    text: string,
    valueChangeHandler: (value: string) => void
}

export const QuillEditor: React.FC<Props> = (props): JSX.Element => {
    const quillRef = useRef<ReactQuill>(null);

    return (
        <ReactQuill modules={{
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

                [{'header': [1, 2, 3, 4, 5, 6, false]}],              // header button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                ['link', 'image', 'video'],          // add's image support
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}]
            ]
        }} ref={quillRef} value={props.text} onChange={props.valueChangeHandler} theme="snow"/>
    );
};
