import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from 'react-router-dom';

interface Props {
    className: string,
    color: string,
    name: string,
    code: string
}

export const SubjectLink: React.FC<Props> = (props: Props): JSX.Element => {
    const history = useHistory();

    const clickHandler = () => {
        history.push('/subject/' + encodeURIComponent(props.className) + '/' + encodeURIComponent(props.code));
    };

    return (
        <div className="subjectLink" onClick={clickHandler}>
            <div className="subjectColorIndicator" style={{backgroundColor: props.color}}/>
            <div className="subjectLinkText">{props.name} <FontAwesomeIcon icon={faChevronRight}/></div>
        </div>
    );
};
