import React from "react";
import {VerticalPlaceholder} from "./VerticalPlaceholder";

export const Overlay: React.FC = (props): JSX.Element => {
        return (
            <div>
                <div style={{background: 'rgba(0,0,0,0.7)', position: "fixed", width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 9999}}>
                    <VerticalPlaceholder height="2em"/>
                    {props.children}
                </div>
            </div>
        );
};
