import React, {useContext, useEffect, useState} from "react";
import {deleteBodyAuthenticated, getAuthenticated, postBodyAuthenticated} from "../../../util/Requests";
import {assignmentURL, noError} from "../../../util/Globals";
import {UserContext} from "../../../login/UserContext";
import {ResponseWrapper} from "../../../util/Types";
import {Form, ListGroup} from "react-bootstrap";
import {AssignmentFileDisplay} from "./AssignmentFileDisplay";
import {ConfirmationPopup} from "../../../components/ConfirmationPopup";
import {IconButton} from "../../../components/IconButton";
import {faList, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";

interface Props {
    assignmentID: string,
    refresh?: () => void,
    useSelect: boolean
}

export const AssignmentFileList: React.FC<Props> = (props: Props): JSX.Element => {
    const [filenames, setFilenames] = useState([] as string[]);
    const [selectedFiles, setSelectedFiles] = useState([] as string[]);
    const [showSelect, setShowSelect] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const userContext = useContext(UserContext);

    const deleteFiles = async () => {
        try {
            const wrapper = await deleteBodyAuthenticated(assignmentURL + '/files/' + props.assignmentID + '/multiple', userContext.user.token, {files: selectedFiles}) as ResponseWrapper;

            if (wrapper.error === noError) {
                loadFilenames();
            } else {
                console.log(wrapper.error);
            }

            setSelectedFiles([]);
            setShowSelect(false);
            setDeleteConfirmation(false);
        } catch (e) {
            console.log(e);
            setDeleteConfirmation(false);
        }
    };

    const enableSelect = () => {
        if (props.useSelect) {
            setSelectedFiles([]);
            setShowSelect(true);
        }
    };

    const allSelected = () => {
        return selectedFiles.length === filenames.length;
    };

    const toggleSelectAll = () => {
        if (allSelected()) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(filenames);
        }

    };

    const isSelected = (id: string): boolean => {
        return selectedFiles.includes(id);
    };

    const setSelected = (filename: string, value: boolean) => {
        if (value) {
            if (isSelected(filename)) {
                return;
            } else {
                const newAssignments = selectedFiles.slice();
                newAssignments.push(filename);
                console.log(newAssignments);
                setSelectedFiles(newAssignments);
            }
        } else {
            if (isSelected(filename)) {
                const newAssignments = selectedFiles.slice();
                newAssignments.splice(newAssignments.indexOf(filename), 1);
                console.log(newAssignments);
                setSelectedFiles(newAssignments);
            } else {
                return;
            }
        }
    };

    const loadFilenames = async () => {
        try {
            const wrapper = await getAuthenticated(assignmentURL + '/files/' + props.assignmentID, userContext.user.token) as ResponseWrapper;

            if (wrapper.error === noError) {
                setFilenames(wrapper.result);
            } else {
                console.log(wrapper.error);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        loadFilenames();
    }, []);

    if (filenames.length === 0) {
        return <div/>;
    }

    if (!props.useSelect) {
        return (
            <div>
                <ListGroup variant="flush">
                    {
                        filenames.map(filename => {
                            return <AssignmentFileDisplay key={filename} assignmentID={props.assignmentID} filename={filename} showSelect={showSelect} selected={isSelected(filename)}/>;
                        })
                    }
                </ListGroup>
                <VerticalPlaceholder height="1.5em"/>
            </div>
        );
    }

    return (
        <div>
            <ListGroup variant="flush">
                <ConfirmationPopup text={"Sollen diese " + selectedFiles.length + " Dateien wirklich gelöscht werden?"}
                                   show={deleteConfirmation}
                                   onConfirm={deleteFiles}
                                   onCancel={() => {
                                       setDeleteConfirmation(false);
                                       setSelectedFiles([]);
                                   }}
                />
                {
                    filenames.map(filename => {
                        return <AssignmentFileDisplay key={filename} onToggleSelect={setSelected} assignmentID={props.assignmentID} filename={filename} showSelect={showSelect} selected={isSelected(filename)}/>;
                    })
                }
                {
                    showSelect
                    ? <ListGroup.Item key="buttons" className="align-items-start">
                            <span className="mr-5"/>
                            <Form.Check checked={allSelected()} onChange={toggleSelectAll} className="float-left mr-3"/>
                            <IconButton onClick={() => {
                                setSelectedFiles([]);
                                setShowSelect(false);
                            }} className="float-left mr-3" size="sm" icon={faTimes}>Abbrechen</IconButton>
                            <IconButton onClick={() => setDeleteConfirmation(true)} className="float-left mr-3" variant="danger" size="sm" icon={faTrashAlt}>Ausgewählte Löschen</IconButton>
                        </ListGroup.Item>
                        : <ListGroup.Item key="buttons" className="align-items-start">
                            <IconButton onClick={enableSelect} className="float-left mr-3" size="sm" icon={faList}>Auswählen</IconButton>
                        </ListGroup.Item>
                }
            </ListGroup>
            <VerticalPlaceholder height="1.5em"/>
        </div>
    );
};
