import React from "react";
import {Container, Spinner} from "react-bootstrap";
import {SubjectLink} from "./SubjectLink";
import {subjects} from "../../../config/subjects";

interface Props {
    className: string
}

export const SubjectBlock: React.FC<Props> = (props: Props): JSX.Element => {
    const subjectElements = () => {
        const elements = [];

        for (let subject of subjects) {
            elements.push(<SubjectLink className={props.className} key={subject.code} color={subject.color} name={subject.name} code={subject.code}/>);
        }

        return elements;
    };

    return (
      <Container>
          { subjectElements() }
      </Container>
    );
};
