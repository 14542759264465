import React, {useContext} from "react";
import { Redirect } from "react-router-dom";
import {UserContext} from "./UserContext";
export const AuthCheck: React.FC = (): JSX.Element => {
    const {user} = useContext(UserContext);

    if (user.loggedIn) {
        return <Redirect to={'/class'}/>;
    } else {
        console.log('Back to login (AuthCheck)');
        return <Redirect to={'/login'}/>;
    }
};
