import React, {useEffect, useState} from "react";
import {Popup} from "./Popup";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "./IconButton";

interface Props {
    text: string,
    show: boolean,
    onClose: () => void
}

export const InfoPopup: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const closeHandler = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        props.onClose();
    };

    useEffect(() => setLoading(false), [props.show]);

    return (
        <Popup show={props.show}>
            <Card>
                <Card.Header>
                    <FontAwesomeIcon onClick={closeHandler} className="float-right" icon={faTimes}/>
                </Card.Header>
                <Card.Body>
                    {props.text}
                </Card.Body>
                <Card.Footer>
                    <IconButton icon={faCheck} loading={loading} className="mr-3" variant="success" onClick={closeHandler}>Okay</IconButton>
                </Card.Footer>
            </Card>
        </Popup>
    )
};
