import React, {Ref, useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload, faPlus} from "@fortawesome/free-solid-svg-icons";
import {CustomFileInput} from "./CustomFileInput";
import {VerticalPlaceholder} from "./VerticalPlaceholder";

interface Props {
    label?: string, // Text to be displayed on the component when idle
    dropLabel?: string, // Text to be displayed on the component when hovered with a file
    handleFileTransfer?: (files: File[]) => void
}

export const DragAndDrop: React.FC<Props> = (props: Props): JSX.Element => {
    let dragCounter = 0;
    const [hover, setHover] = useState(false);
    const dragElement: Ref<HTMLDivElement> = useRef(null);

    const label = props.label ? props.label : 'Datei hier ablegen';
    const dropLabel = props.dropLabel ? props.dropLabel : 'Datei in dieses Feld ziehen';

    const handleDrop = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setHover(false);

        if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            if (props.handleFileTransfer) {
                const files: File[] = [];

                for (let i = 0; i < event.dataTransfer.files.length; ++i) {
                    files.push(event.dataTransfer.files.item(i)!);
                }

                props.handleFileTransfer(files);
            } else {
                console.log('Drag And Drop: No handler attached');
            }
        }
    };

    // Needed to prevent the browser from opening the file by default
    const handleDrag = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            if (event.target === dragElement.current) {
                setHover(true);
            }
        }
    };

    const handleDragOut = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.target === dragElement.current) {
            const targetsChild = dragElement.current && dragElement.current.contains(event.relatedTarget as Node);

            if (!targetsChild) {
                setHover(false);
            }
        }
    };

    const applyHandlers = () => {
        if (!dragElement.current) {
            return;
        }

        dragElement.current!.addEventListener('dragenter', handleDragIn);
        dragElement.current!.addEventListener('dragleave', handleDragOut);
        dragElement.current!.addEventListener('dragover', handleDrag);
        dragElement.current!.addEventListener('drop', handleDrop);
    };

    const removeHandlers = () => {
        if (!dragElement.current) {
            return;
        }

        dragElement.current!.removeEventListener('dragenter', handleDragIn);
        dragElement.current!.removeEventListener('dragleave', handleDragOut);
        dragElement.current!.removeEventListener('dragover', handleDrag);
        dragElement.current!.removeEventListener('drop', handleDrop);
    };

    useEffect(() => {
        dragCounter = 0;
    });

    useEffect(() => {
        applyHandlers();

        // Returned function will be called on component unmount
        return removeHandlers;
    });

    if (hover) {
        return (
            <div>
            <Container ref={dragElement} className="dragdrop-hover">
                <span className="dragdrop-text"><FontAwesomeIcon icon={faFileUpload}/> {dropLabel}</span>
            </Container>
                <VerticalPlaceholder height="0.7em"/>
                oder <CustomFileInput handleFileTransfer={props.handleFileTransfer} multiple={true}/>
            </div>
        );
    } else {
        return (
            <div>
            <Container ref={dragElement} className="dragdrop">
                <span className="dragdrop-text"><FontAwesomeIcon icon={faPlus}/> {label}</span>
            </Container>
                <VerticalPlaceholder height="0.7em"/>
                oder <CustomFileInput handleFileTransfer={props.handleFileTransfer} multiple={true}/>
            </div>
        );
    }
};
