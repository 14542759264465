import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Card, Container, Image, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faKey, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory, withRouter} from "react-router-dom";
import {ResponseWrapper, UserSummary} from "../util/Types";
import {UserContext} from "./UserContext";
import {loginRequest} from "../requests/sessions";
import {getAuthenticated} from "../util/Requests";
import {fetchClassURL, noError, schoolName, userRolesURL, validationURL} from "../util/Globals";
import {RoleContext} from "./RoleContext";
import {ClassNameContext} from "./ClassNameContext";
import {MainContentSpinner} from "../components/MainContentSpinner";
import {VerticalPlaceholder} from "../components/VerticalPlaceholder";

interface Props {
}

export const LoginPageComponent: React.FC<Props> = (props: Props): JSX.Element => {
    const usernameInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('none');
    const [displayPassword, setDisplayPassword] = useState(false);

    // Convert the stored item (string|null) to a boolean
    const [stayLoggedIn, setStayLoggedIn] = useState(localStorage.getItem('stayLoggedIn') === 'true');

    const userContext = useContext(UserContext);
    const roleContext = useContext(RoleContext);
    const classNameContext = useContext(ClassNameContext);

    // @ts-ignore
    const redirect = props.match.params.redirect;

    const fullReset = () => {
        roleContext.setRoles([]);
        classNameContext.setClassName('');
    };

    const authCheck = async () => {
        console.log('auth check');

        if (userContext.user.loggedIn) {
            await loadRoles();
            await loadClass();
            setLoading(true);

            if (redirect) {
                console.log('Redirecting... ', redirect);
                let target = decodeURIComponent(redirect);

                if (target.startsWith('/login') || target.startsWith('login')) {
                    // Never redirect back to login page
                    target = '/class/home';
                }

                history.push(target);
            } else {
                history.push('/class/home');
                console.log('Going to class home');
            }

            setLoading(false);
        } else {
            console.log('not logged in');
        }
    };

    const checkStored = () => {
        console.log('Checking for stored token');

        const storedToken = localStorage.getItem('token');

        if (!storedToken) {
            console.log('No token found');
            return;
        }

        setLoading(true);
        setError(noError);

        console.log('Found token', storedToken);

        getAuthenticated(validationURL, storedToken)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                console.log('Result:', wrapper);

                if (wrapper.error === noError) { // Means the stored token is valid
                    const loadedUser = wrapper.result as UserSummary;

                    userContext.setUser({
                        token: storedToken,
                        name: loadedUser.name,
                        loggedIn: true
                    });

                    document.cookie = 'token=' + storedToken + '; path=/;';

                    authCheck();
                } else {
                    localStorage.removeItem('token');
                    setError('Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.');
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setLoading(false);
            });
    };

    const loginAttempt = () => {
        if (loading) { // If any request is already ongoing, cancel
            return;
        }

        if (!usernameInput || !usernameInput.current || !passwordInput || !passwordInput.current) {
            setError('Ein Fehler ist aufgetreten, bitte probieren Sie es noch einmal!');
            return;
        }

        const username = usernameInput.current.value;
        const password = passwordInput.current.value;

        setLoading(true); // Visual only, changes button text to 'Loading...'
        setError(noError);

        loginRequest(username, password)
            .then((data) => {
                const wrapper = data as ResponseWrapper;
                console.log(data);

                if (wrapper && wrapper.error && wrapper.result) {
                    if (wrapper.error === 'none') {
                        userContext.setUser({
                            name: username,
                            token: wrapper.result.token,
                            loggedIn: true
                        });

                        if (stayLoggedIn) {
                            localStorage.setItem('token', wrapper.result.token);
                        }

                        document.cookie = 'token=' + wrapper.result.token + '; path=/;';

                        console.log('New user set');
                    } else {
                        setError(wrapper.error.toString());
                        setLoading(false);
                    }
                } else {
                    setError('Fehler: Die Antwort des Servers war ungültig. Bitte einen Administrator kontaktieren!');
                }
            })
            .catch(reason => {
                setLoading(false);
                setError('Wir konnten den Server nicht erreichen, ist das Internet ausgefallen?');
                console.log(reason);
            });
    };

    // Silently loads roles assigned to user in the background
    const loadRoles = (): Promise<void> => {
        setLoading(true);

        return new Promise(resolve => {
            if (!userContext.user.loggedIn) {
                roleContext.setRoles([]);
                resolve();
            }

            getAuthenticated(userRolesURL, userContext.user.token)
                .then(response => {
                    const wrapper = response as ResponseWrapper;

                    if (wrapper.error === noError) {
                        roleContext.setRoles(wrapper.result);
                    } else {
                        console.log('Could not load roles: ', wrapper.error);
                    }
                })
                .catch(console.log)
                .finally(() => {
                    setLoading(false);
                    resolve();
                });
        });
    };

    const loadClass = (): Promise<void> => {
        setLoading(true);

        return new Promise(resolve => {
            console.log('Fetching class name');
            getAuthenticated(fetchClassURL, userContext.user.token)
                .then(response => {
                    const wrapper = response as ResponseWrapper;

                    if (wrapper.error === noError) {
                        classNameContext.setClassName(wrapper.result);
                    } else {
                        console.log(wrapper.error);
                    }
                })
                .catch(console.log)
                .finally(() => {
                    setLoading(false);
                    resolve();
                });
        });
    };

    const handleLogin = (event: FormEvent) => {
      event.preventDefault();
      loginAttempt();
    };

    const toggleStayLoggedIn = () => {
        const newValue = !stayLoggedIn;
        localStorage.setItem('stayLoggedIn', String(newValue));
        setStayLoggedIn(newValue);
    };

    useEffect(() => {
        authCheck();
    }, [userContext.user]);

    useEffect(fullReset, []);
    useEffect(checkStored, []);

    return (
      <div>
          <VerticalPlaceholder height="3em"/>
          <Container>
              <Image alt="" src="/branding.jpg" style={{maxHeight: '250px'}}/>
          </Container>

          <VerticalPlaceholder height="3em"/>

          <Container>
              <Card>
                  <Card.Header>
                      Willkommen auf der Lernplattform der {schoolName}!
                  </Card.Header>
                  <Card.Body>
                      {
                        loading ? <MainContentSpinner/>
                        : <Form onSubmit={handleLogin}>
                                <Form.Group controlId={"loginFormUsername"}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><FontAwesomeIcon icon={faUserAlt}/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control ref={usernameInput} placeholder={"Benutzername"}/>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId={"loginFormPassword"}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><FontAwesomeIcon icon={faKey}/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control ref={passwordInput} type={displayPassword ? "text" : "password"} placeholder={"Passwort"}/>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId={"displayPassword"}>
                                    <Form.Check inline label="Passwort anzeigen" type="checkbox" checked={displayPassword} onChange={() => setDisplayPassword(!displayPassword)}/>
                                    <Form.Check inline label="Angemeldet bleiben" type="checkbox" checked={stayLoggedIn} onChange={toggleStayLoggedIn}/>
                                </Form.Group>

                                <Button variant="success" type="submit" block disabled={loading}>
                                    {loading ? 'Lade...' : 'Anmelden'}
                                </Button>
                            </Form>
                        }
                  </Card.Body>
                  <Card.Footer>
                      { error === 'none' ? <span/> : (<Alert variant="danger"><FontAwesomeIcon icon={faExclamationCircle}/> {error}</Alert>) }
                  </Card.Footer>
              </Card>
          </Container>
      </div>
    );
};

export const LoginPage = withRouter(LoginPageComponent);
