import React, {useContext} from "react";
import {Route, Redirect, useLocation} from "react-router-dom";
import {UserContext} from "../login/UserContext";

interface Props {
    roles?: string|string[],
    association?: object,
    exact?: boolean,
    path?: string|string[]
}

export const AuthenticatedRoute: React.FC<Props> = (props): JSX.Element => {
    const {user} = useContext(UserContext);
    const location = useLocation();

    if (!user.loggedIn) {
        const loginPath = '/login/' + (props.path ? encodeURIComponent(location.pathname.toString()) : '');
        return <Redirect to={loginPath}/>;
    }

    return (
        <Route exact={props.exact} path={props.path}>
            {props.children}
        </Route>
    );
};
