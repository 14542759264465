import React, {useContext, useEffect, useState} from "react";
import {feedbackSummary, ResponseWrapper} from "../util/Types";
import {Card, ListGroup} from "react-bootstrap";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {RenderHTML} from "./RenderHTML";
import {feedbackURL, makeAnswerFileURL, makeFeedbackFileURL, noError} from "../util/Globals";
import {getFileIcon} from "../util/icons";
import {QuillEditor} from "./QuillEditor";
import {faCheck, faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "./IconButton";
import {DragAndDrop} from "./DragAndDrop";
import {postBodyAuthenticated, postFormAuthenticated} from "../util/Requests";
import {UserContext} from "../login/UserContext";
import {ErrorAlert} from "./ErrorAlert";

interface Props {
    feedback: feedbackSummary
    refresh?: () => void
}

export const FeedbackDisplayTeacher: React.FC<Props> = (props: Props): JSX.Element => {
    const hasText = props.feedback.text && props.feedback.text.length > 0;
    const hasFiles = props.feedback.files && props.feedback.files.length > 0;

    const userContext = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);

    const [text, setText] = useState(props.feedback.text);

    const discard = () => {
        setEditing(false);
        setText(props.feedback.text);
    };

    const refresh = () => {
        if (props.refresh) {
            console.log('Executing refresh');
            props.refresh();
        }
    };

    const saveChanges = () => {
        setEditing(false);
        setLoading(true);

        const url = feedbackURL + '/text/' + props.feedback.assignment + '/' + props.feedback.user;
        postBodyAuthenticated(url, userContext.user.token, {text})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    refresh();
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError("Server konnte nicht erreicht werden");
                setLoading(false);
            });
    };

    const sendFiles = (files: File[]) => {
        setLoading(true);

        const url = feedbackURL + '/files/' + props.feedback.assignment + '/' + props.feedback.user;

        const fileObject: any = {};

        files.forEach((file, index) => {
            fileObject['file' + index] = file;
        });

        postFormAuthenticated(url, userContext.user.token, fileObject)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    refresh();
                    setLoading(false);
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError("Server konnte nicht erreicht werden");
                setLoading(false);
            });
    };

    const valueChangeHandler = (value: string) => {
        if (loading) {
            return;
        }

        setText(value);
    };

    const textElement = () => {
        if (editing) {
            return <div>
                <VerticalPlaceholder height="1em"/>
                <QuillEditor text={text} valueChangeHandler={valueChangeHandler}/>
                <VerticalPlaceholder height="1em"/>
                <IconButton loading={loading} icon={faTimes} size="sm" variant='danger' className="mr-4 float-right" onClick={discard}>Verwerfen</IconButton>
                <IconButton loading={loading} icon={faCheck} size="sm" variant='success' className="mr-4 float-right" onClick={saveChanges}>Änderungen speichern</IconButton>
            </div>;
        }

        if (hasText) {
            return <div>
                <VerticalPlaceholder height="1em"/>
                <RenderHTML html={text}/>
                <VerticalPlaceholder height="1em"/>
                <IconButton loading={loading} icon={faEdit} size="sm" variant='primary' className="mr-4 float-right" onClick={() => setEditing(true)}>Text Bearbeiten</IconButton>
            </div>
        } else {
            return <div>
                <VerticalPlaceholder height="1em"/>
                <IconButton loading={loading} icon={faEdit} variant='primary' onClick={() => setEditing(true)}>Text Hinzufügen</IconButton>
            </div>;
        }
    };

    const fileElement = () => {
        if (hasFiles) {
            return <div>
                <VerticalPlaceholder height="3em"/>
                <Card.Title>Korrigierte Dateien</Card.Title>
                <ListGroup variant="flush">
                    {
                        props.feedback.files!.map(file => {
                            return <ListGroup.Item key={file} action onClick={() => {
                                window.open(makeFeedbackFileURL(props.feedback.assignment, props.feedback.user, file))
                            }}>
                                <span className={"mr-5"}/>
                                <span className={"font-large float-left mr-4 " + getFileIcon(file)}/>
                                <span className={"float-left"}>{file}</span>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
            </div>;
        } else {
            return <div>
                <VerticalPlaceholder height="3em"/>
                <Card.Title>Noch keine korrigierten Dateien hinzugefügt</Card.Title>
            </div>;
        }
    };

    useEffect(() => {
        setText(props.feedback.text);
    }, [props.feedback.text]);

    return <Card>
        <Card.Body>
            <Card.Title>Rückmeldung an den Schüler</Card.Title>
            { textElement() }
            { fileElement() }
            <VerticalPlaceholder height="1.5em"/>
            <DragAndDrop label={"Korrigierte Datei in dieses Feld ziehen"} dropLabel={"Datei hier ablegen"} handleFileTransfer={sendFiles}/>
        </Card.Body>
        <Card.Footer>
            <ErrorAlert error={error}/>
        </Card.Footer>
    </Card>;
};
