import {userDetails, UserSummary} from "./Types";

export const compareUserDetails = (a: userDetails, b: userDetails) => {
    const alphabeticalScore = a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'de');

    if (typeof a.className === 'undefined' || typeof b.className === 'undefined' || !a.className || !b.className) {
        return alphabeticalScore;
    }

    const classScore = compareClass(a.className, b.className);

    return classScore === 0 ? alphabeticalScore : classScore;
};

export const scoreClass = (className: string): number => {
    const contains1 = className.includes('1');
    const contains2 = className.includes('2');
    const contains3 = className.includes('3');
    const contains4 = className.includes('4');

    if (contains4) {
        return contains3 ? 7 : 8;
    }

    if (contains3) {
        return contains2 ? 5 : 6;
    }

    if (contains2) {
        return contains1 ? 3 : 4;
    }

    if (contains1) {
        return 2;
    }

    return 1;
};

export const compareClass = (classA: string, classB: string) => {
    const score = scoreClass(classA) - scoreClass(classB);

    if (score !== 0) {
        return score;
    } else {
        return classA.toLowerCase().replace(/[0-4]/g, '').localeCompare(classB.toLowerCase().replace(/[0-4]/g, ''), 'de');
    }
};

export const sortUsersAlphabetically = (users: UserSummary[]) => {
    console.log("sorting");
    console.log(users);
    users.sort((userA, userB) => {
        return userA.name.localeCompare(userB.name);
    });
    return users;
};
