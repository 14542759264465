import React from "react";
import {Form, ListGroup} from "react-bootstrap";
import {makeAssignmentFileURL} from "../../../util/Globals";
import {getFileIcon} from "../../../util/icons";

interface Props {
    assignmentID: string,
    filename: string,
    showSelect: boolean,
    selected: boolean,
    onToggleSelect?: (assignmentID: string, newValue: boolean) => void
}

export const AssignmentFileDisplay: React.FC<Props> = (props: Props): JSX.Element => {
    const toggleHandler = () => {
        if (typeof props.onToggleSelect !== 'undefined') {
            props.onToggleSelect(props.filename, !props.selected);
        }
    };

    if (props.showSelect) {
        return (
            <ListGroup.Item action onClick={toggleHandler}>
                <span className="float-left mr-5"/>
                <Form.Check className="float-left mr-3" checked={props.selected} onChange={toggleHandler}/>
                <span className={"font-large float-left mr-4 " + getFileIcon(props.filename)}/>
                <span className="float-left">{props.filename}</span>
            </ListGroup.Item>
        );
    } else {
        return (
            <ListGroup.Item action onClick={() => {
                window.open(makeAssignmentFileURL(props.assignmentID, props.filename));
            }}>
                <span className="float-left mr-5"/>
                <span className={"font-large float-left mr-4 " + getFileIcon(props.filename)}/>
                <span className="float-left">{props.filename}</span>
            </ListGroup.Item>
        );
    }
};
