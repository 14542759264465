export const timeElapsed = (timestamp: string) => {
    const date = new Date(timestamp);

    const now = new Date();

    const difference = now.getTime() - date.getTime();

    const seconds = difference / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (weeks >= 2) {
        return Math.floor(weeks) + " Wochen";
    }

    if (days >= 2) {
        return Math.floor(days) + " Tagen";
    }

    if (hours >= 2) {
        return Math.floor(hours) + " Stunden";
    }

    if (minutes >= 1) {
        if (minutes === 1) {
            return "1 Minute"
        }
        return Math.floor(minutes) + " Minuten";
    }

    return Math.floor(seconds) + " Sekunden";
};
