import React from "react";
import {Overlay} from "./Overlay";
import {Container} from "react-bootstrap";

type Props = React.PropsWithChildren<{
    show: boolean
}>;

export const Popup: React.FC<Props> = (props: Props): JSX.Element => {
    if (props.show) {
        return (
            <Overlay>
                <Container style={{opacity: '100%'}}>
                    {props.children}
                </Container>
            </Overlay>
        );
    } else {
        return <span/>;
    }
};
