import React, {useContext} from "react";
import {Button, Card, Container} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {useHistory} from "react-router-dom";
import {RoleContext} from "../../login/RoleContext";
import {hasRole} from "../../util/Roles";
import {teacherRole} from "../../util/Globals";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {StudentNavbar} from "../../navigation/StudentNavbar";

export const NoClassAssignedErrorPage: React.FC = (): JSX.Element => {
    const history = useHistory();
    const roleContext = useContext(RoleContext);

    if (hasRole(roleContext.roles, teacherRole)) {
        return (
            <Container>
                <TeacherNavbar/>
                <VerticalPlaceholder height="1.5em"/>
                <Card>
                    <Card.Body>
                        <Card.Title>Ihnen ist keine Klasse zugewiesen</Card.Title>
                        <Card.Text>Sie können unter "Alle Klassen" eine Klasse auswählen, die Ihre Startseite sein soll. Klicken Sie einfach auf das kleine Sternchen!</Card.Text>
                        <VerticalPlaceholder height="2em"/>
                        <Button onClick={() => history.push('/classes')}>Zu den Klassen</Button>
                    </Card.Body>
                </Card>
            </Container>
        );
    } else {
        return (
            <Container>
                <StudentNavbar/>
                <VerticalPlaceholder height="1.5em"/>
                <Card>
                    <Card.Body>
                        <Card.Title>Du bist in keine Klasse eingetragen</Card.Title>
                        <Card.Text>Sag bitte deinem Lehrer Bescheid, dann bringen wir das in Ordnung!</Card.Text>
                        <VerticalPlaceholder height="2em"/>
                        <Button onClick={history.goBack}>Zurück</Button>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
};
