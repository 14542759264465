import React, {useContext, useEffect, useState} from "react";
import {AssignmentListDisplay, ResponseWrapper} from "../util/Types";
import {getAuthenticated} from "../util/Requests";
import {feedbackURL, noError, pollDelayLong} from "../util/Globals";
import {UserContext} from "../login/UserContext";
import {Card, Container, Image, ListGroup} from "react-bootstrap";
import {Popup} from "../components/Popup";
import {IconButton} from "../components/IconButton";
import {faCheck, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {timeElapsed} from "../util/dates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";

export const NewFeedbackList: React.FC = (): JSX.Element => {
    const [newFeedback, setNewFeedback] = useState([] as AssignmentListDisplay[]);
    const [display, setDisplay] = useState(false);

    const userContext = useContext(UserContext);
    const history = useHistory();

    const loadNewFeedback = () => {
        getAuthenticated(feedbackURL + '/unread', userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setNewFeedback(wrapper.result);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            });
    };

    const imageSource = () => {
        if (newFeedback.length > 0) {
            return "/ghost_mail_highlight.png";
        } else {
            return "/ghost_mail.png";
        }
    };

    const open = (assignmentID: string) => {
        setDisplay(false);
        history.push('/assignment/' + assignmentID);
    };

    useEffect(() => {
        loadNewFeedback();
        const refreshInterval = setInterval(loadNewFeedback, pollDelayLong);
        return () => {
            clearInterval(refreshInterval);
        };
    }, []);

    return <div style={{display: "inline-block"}} className="mr-3">
        <Popup show={display}>
            { newFeedback.length > 0 ?
                <Card style={{display: "block", margin: "auto"}}>
                    <Card.Body>
                        <ListGroup>
                            {
                                newFeedback.map(feedbackItem => {
                                   return <ListGroup.Item action key={feedbackItem.id} onClick={() => open(feedbackItem.id)}>
                                       <div className="d-inline-block mr-3">
                                           Du hast eine Rückmeldung für deine Abgabe zu "{feedbackItem.title}" bekommen
                                           <br/><span style={{fontSize: "smaller", color: "gray"}}>vor {timeElapsed(feedbackItem.updated)}</span>
                                       </div>
                                       <div className="float-right" style={{paddingTop: "0.5em"}}>
                                           <FontAwesomeIcon style={{fontSize: "1.6em"}} icon={faChevronRight}/>
                                       </div>
                                   </ListGroup.Item>
                                })
                            }
                        </ListGroup>
                        <hr/>
                        <IconButton icon={faCheck} onClick={() => setDisplay(false)}>Okay</IconButton>
                    </Card.Body>
                </Card>
                :
                <Card style={{display: "block", margin: "auto"}}>
                    <Card.Body>
                        <Card.Text>Du hast keine ungelesenen Rückmeldungen. Schau doch später nochmal rein!</Card.Text>
                        <hr/>
                        <IconButton icon={faCheck} onClick={() => setDisplay(false)}>Okay</IconButton>
                    </Card.Body>
                </Card>
            }
        </Popup>
        <Image style={{cursor: "pointer"}} src={imageSource()} height="40px" onClick={() => setDisplay(true)}/>
    </div>;
};
