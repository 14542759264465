export const supportedIcons = [
    'pdf',
    'doc',
    'docx',
    'mp3',
    'mp4',
    'mpeg',
    'mpg',
    'png',
    'jpg',
    'jpeg',
    'bmp',
    'zip',
    'rar'
];

export const getFileIcon = (filename: string) => {
    const split = filename.split('.');
    // Last item of split array or blank if file has no extension
    const extension = split.length === 1 ? '' : split[split.length - 1];

    if (supportedIcons.includes(extension)) {
        return 'fiv-viv fiv-icon-' + extension;
    } else {
        return 'fiv-viv fiv-icon-blank';
    }
};
