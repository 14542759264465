import React, {useContext, useState} from "react";
import {UserContext} from "../../../login/UserContext";
import {Card, Col, ListGroup, ProgressBar, Row} from "react-bootstrap";
import {ResponseWrapper, userDetails, UserManagementJob} from "../../../util/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faGlobe, faPause, faPlay, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";
import {IconButton} from "../../../components/IconButton";
import {deleteBodyAuthenticated, postBodyAuthenticated} from "../../../util/Requests";
import {generatePDFURL, makePDFURL, noError, pauseJobURL, startJobURL, userJobURL} from "../../../util/Globals";
import {ConfirmationPopup} from "../../../components/ConfirmationPopup";
import {ErrorAlert} from "../../../components/ErrorAlert";
import {compareUserDetails} from "../../../util/compare";

interface Props {
    job: UserManagementJob,
    triggerReload?: () => void
}

export const UserJobControlPanel: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const userContext = useContext(UserContext);

    const total = props.job.completed.length + props.job.data.length;
    const percentage = Math.round(props.job.completed.length / total * 100);
    const label = props.job.completed.length + '/' + total;
    const done = props.job.completed.length === total;

    const deleteClickHandler = () => {
        setShowDeleteConfirmation(true);
    };

    const generatePDF = () => {
        if (!done) {
            console.log('Generate cancelled, job still running');
            return;
        }

        const userDetails = [] as userDetails[];

        if (props.job.type === 'createStudents') {
            for (let item of props.job.completed) {
                const name = item[0] + ' ' + item[1];
                const username = item[2];
                const className = item[3];
                const password = item[4];

                userDetails.push({
                    name,
                    username,
                    className,
                    password
                });
            }
        }

        if (props.job.type === 'createTeachers') {
            for (let item of props.job.completed) {
                const name = item[0] + ' ' + item[1];
                const username = item[2];
                const password = item[3];

                userDetails.push({
                    name,
                    username,
                    password
                });
            }
        }

        if (userDetails.length === 0) {
            console.log('Generate cancelled, no items');
            return;
        }

        console.log(userDetails);

        userDetails.sort(compareUserDetails);

        console.log(userDetails);

        setGenerating(true);

        postBodyAuthenticated(generatePDFURL, userContext.user.token, {users: userDetails})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    window.open(makePDFURL(wrapper.result));
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            })
            .finally(() => setGenerating(false));
    };

    const itemToColumns = (item: any) => {
        if (item instanceof Array) {
            return (item as Array<string>).map((single, index) => {
                return <Col key={index}>{single}</Col>;
            });
        } else {
            return <Col>{item}</Col>;
        }
    };

    const reload = () => {
        if (props.triggerReload) {
            props.triggerReload();
        }
    };

    const startJob = () => {
        setLoading(true);

        postBodyAuthenticated(startJobURL, userContext.user.token)
            .then(response => {
                reload();
            })
            .catch(reason => console.log(reason))
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    const pauseJob = () => {
        setLoading(true);

        postBodyAuthenticated(pauseJobURL, userContext.user.token)
            .then(response => {
                reload();
            })
            .catch(reason => console.log(reason))
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    const deleteJob = () => {
        setLoading(true);

        deleteBodyAuthenticated(userJobURL, userContext.user.token)
            .then(response => {
                reload();
            })
            .catch(reason => console.log(reason))
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    return (
        <Card>
            <ConfirmationPopup text={"Wollen Sie den aktuellen Job verwerfen?"} show={showDeleteConfirmation} onConfirm={deleteJob} onCancel={() => setShowDeleteConfirmation(false)}/>
            <Card.Header>
                <Row>
                    <Col>
                        <ProgressBar striped animated={props.job.running} variant="success" now={percentage}/>
                    </Col>
                    <Col>
                        {label}
                    </Col>
                </Row>
                <VerticalPlaceholder height="1em"/>
                <Row>
                    <Col>
                        {
                            props.job.running ? <IconButton onClick={pauseJob} icon={faPause} variant="info" loading={loading}>Pausieren</IconButton> : <IconButton disabled={done} onClick={startJob} icon={faPlay} variant="success" loading={loading}> {done ? 'Fertig!' : 'Starten'}</IconButton>
                        }
                        {
                            done ? <IconButton  className="ml-2" icon={faGlobe} onClick={generatePDF}>Infokärtchen generieren</IconButton> : ''
                        }
                    </Col>
                    <Col>
                        <IconButton onClick={deleteClickHandler} icon={faTrashAlt} variant="danger" loading={loading}>Löschen</IconButton>
                    </Col>
                </Row>
                <VerticalPlaceholder height="1em"/>
                <ErrorAlert error={props.job.error}/>
            </Card.Header>
            <Card.Body>
                <ListGroup>
                    {
                        props.job.completed.map((value, index) => {
                            return (
                                <ListGroup.Item key={'completed' + index} disabled>
                                    <Row>
                                    {itemToColumns(value)} <FontAwesomeIcon style={{color: '#00cc00'}} icon={faCheck}/>
                                    </Row>
                                </ListGroup.Item>
                            )
                        })
                    }
                    {
                        props.job.data.map((value, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                    <Row>
                                    {itemToColumns(value)}
                                    </Row>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
