export const classNamesUppercase = true;
export const useWeeklySchedule = false;

export const scheduleName = useWeeklySchedule ? 'Wochenplan' : 'Arbeitsplan';

export const parseClassName = (className: string) => {
   if (classNamesUppercase) {
       return className.toUpperCase();
   } else {
       return className.toLowerCase();
   }
};
