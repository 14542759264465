import {UserRoleSummary} from "./Types";

export const getRoles = (token: string) => {
    // TODO Load Roles from backend and save their names in 'roles'
};

// Does the logged in User have the specified role?
export const hasRole = (roles: UserRoleSummary[], targetRole: string) => {

    if (typeof roles === 'undefined' || !roles || roles.length === 0) {
        console.log('Roles undefined or empty');
        return false;
    }

    for (let role of roles) {
        if (role.roleName.toLowerCase() === targetRole.toLowerCase()) {
            return true;
        }
    }

    return false;
};
