import React, {useContext, useEffect, useState} from "react";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {Container} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {emptyUserManagementJob, ResponseWrapper, UserManagementJob} from "../../util/Types";
import {UserJobCreator} from "./components/UserJobCreator";
import {UserJobControlPanel} from "./components/UserJobControlPanel";
import {getAuthenticated} from "../../util/Requests";
import {noError, pollDelayShort, userJobURL} from "../../util/Globals";
import {UserContext} from "../../login/UserContext";
import {ErrorAlert} from "../../components/ErrorAlert";
import {MainContentSpinner} from "../../components/MainContentSpinner";

export const UserManagementPage: React.FC = (): JSX.Element => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string|string[]>(noError);
    const [userJob, setUserJob] = useState<UserManagementJob>(emptyUserManagementJob);

    const userContext = useContext(UserContext);

    const applyJob = (job: UserManagementJob) => {
        if (JSON.stringify(job) === JSON.stringify(userJob)) {
            return;
        }

        setUserJob(job);
    };

    const loadInitial = () => {
        setLoading(true);
        setError(noError);

        getAuthenticated(userJobURL, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    applyJob(wrapper.result as UserManagementJob);
                    setLoading(false);
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                setError('Server ist nicht erreichbar');
                setLoading(false);
            });
    };

    const loadBackground = () => {
        getAuthenticated(userJobURL, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    applyJob(wrapper.result as UserManagementJob);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            })
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadInitial();

        const refreshInterval = setInterval(loadBackground, pollDelayShort);
        return () => {
            clearInterval(refreshInterval);
        };
    }, []);

    return (
        <Container>
            <TeacherNavbar/>
            <VerticalPlaceholder height="1.5em"/>
            {
                loading ? <MainContentSpinner/> : (
                    error !== noError ? <ErrorAlert error={error}/> : (
                        userJob.hasJob ? <UserJobControlPanel triggerReload={loadBackground} job={userJob}/> : <UserJobCreator triggerReload={loadBackground}/>
                    )
                )
            }
        </Container>
    );
};
