import React, {useState} from 'react';
import './App.css';
import { UserContext } from './login/UserContext';
import {emptyUser, User, UserRoleSummary} from "./util/Types";
import {Routing} from "./navigation/Routing";
import './css/custom-bootstrap.scss';
import {RoleContext} from "./login/RoleContext";
import { ClassNameContext } from './login/ClassNameContext';

require('popper.js');
require('bootstrap');

function App() {
    const [user, setUser] = useState(emptyUser());
    const [roles, setRoles] = useState<UserRoleSummary[]>([]);
    const [className, setClassName] = useState('');

    const switchUser = (user: User) => setUser(user);
    const switchRoles = (roles: UserRoleSummary[]) => setRoles(roles);
    const switchClass = (className: string) => setClassName(className);

    return (
        <div className="App">
            <RoleContext.Provider value={{roles, setRoles: switchRoles}}>
                <UserContext.Provider value={{user, setUser: switchUser}}>
                    <ClassNameContext.Provider value={{className, setClassName: switchClass}}>
                        <Routing/>
                    </ClassNameContext.Provider>
                </UserContext.Provider>
            </RoleContext.Provider>
        </div>
    );
}

export default App;
