import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {RoleContext} from "../../login/RoleContext";
import {withRouter, RouteComponentProps} from "react-router";
import {NavErrorPage} from "../navError/NavErrorPage";
import {hasRole} from "../../util/Roles";
import {teacherRole} from "../../util/Globals";
import {AssignmentPageTeacher} from "./AssignmentPageTeacher";
import {AssignmentPageStudent} from "./AssignmentPageStudent";

interface Props extends RouteComponentProps{

}

const AssignmentPageComponent: React.FC<Props> = (props: Props): JSX.Element => {
    const history = useHistory();
    const {roles, setRoles} = useContext(RoleContext);

    // @ts-ignore
    let assignmentID = props.match.params.assignment;

    let valid = true;

    if (typeof assignmentID === 'undefined') {
        valid = false;
    }

    assignmentID = decodeURIComponent(assignmentID);

    if (valid) {
        if (hasRole(roles, teacherRole)) {
            return <AssignmentPageTeacher assignmentID={assignmentID}/>;
        } else {
            return <AssignmentPageStudent assignmentID={assignmentID}/>;
        }
    } else {
        return <NavErrorPage/>;
    }


};

export const AssignmentPage = withRouter(AssignmentPageComponent);
