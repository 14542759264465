import React, {useContext, useEffect} from "react";
import {UserContext} from "../login/UserContext";
import {getAuthenticated} from "../util/Requests";
import {noError, validationURL} from "../util/Globals";
import {emptyUser, ResponseWrapper} from "../util/Types";

// Include this component in a page to check token validity every time it is accessed

export const ExpirationChecker: React.FC = (): JSX.Element => {
    const userContext = useContext(UserContext);

    const checkExpired = () => {
        getAuthenticated(validationURL, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error !== noError) { // Means the stored token is no longer valid
                    userContext.setUser(emptyUser());
                }
            })
            .catch(console.log);
    };

    useEffect(checkExpired, []);

    return <span/>;
};
