import React, {useContext, useEffect, useState} from "react";
import {Card, Col, Container, Form, FormGroup, ListGroup, Row} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {ExpirationChecker} from "../../components/ExpirationChecker";
import {deleteBodyAuthenticated, getAuthenticated, postBodyAuthenticated} from "../../util/Requests";
import {associationsURL, classListURL, noError} from "../../util/Globals";
import {UserContext} from "../../login/UserContext";
import {ResponseWrapper} from "../../util/Types";
import {ClassLink} from "./components/ClassLink";
import {ClassNameContext} from "../../login/ClassNameContext";
import {ConfirmationPopup} from "../../components/ConfirmationPopup";


export const ClassListPage: React.FC = (): JSX.Element => {
    const [classes, setClasses] = useState<string[][]>([]);

    const [showAssociatePopup, setShowAssociatePopup] = useState(false);
    const [showDissociatePopup, setShowDissociatePopup] = useState(false);
    const [associateClass, setAssociateClass] = useState('');

    const userContext = useContext(UserContext);
    const classNameContext = useContext(ClassNameContext);

    const loadClasses = () => {
        getAuthenticated(classListURL, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setClasses(wrapper.result);
                } else {
                    console.log('Class List fetch failed: ', wrapper.error);
                }
            })
            .catch(console.log);
    };

    const associate = () => {
        const className = associateClass;

        postBodyAuthenticated(associationsURL, userContext.user.token, {
            key: 'class',
            value: className
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    classNameContext.setClassName(className);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(console.log)
            .finally(() => setShowAssociatePopup(false));
    };

    const dissociate = () => {
        deleteBodyAuthenticated(associationsURL, userContext.user.token, {
            key: 'class'
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    classNameContext.setClassName('');
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(console.log)
            .finally(() => setShowDissociatePopup(false));
    };

    const displayAssociate = (className: string) => {
        setAssociateClass(className);
        setShowAssociatePopup(true);
    };

    const displayDissociate = () => {
        setShowDissociatePopup(true);
    };

    useEffect(loadClasses, []);

    return (
        <Container>
            <ConfirmationPopup text={'Wollen Sie Ihre Startseite zur Klassenseite ' + associateClass + ' ändern?'} show={showAssociatePopup} onConfirm={associate} onCancel={() => setShowAssociatePopup(false)}/>
            <ConfirmationPopup text={'Wollen Sie Ihre Startseite zurücksetzen?'} show={showDissociatePopup} onConfirm={dissociate} onCancel={() => setShowDissociatePopup(false)}/>
            <ExpirationChecker/>
            <TeacherNavbar/>
            <VerticalPlaceholder height="1.5em"/>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Klassenliste
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        {
                            classes.map((classBlock, index) => {
                                return (
                                    <ListGroup.Item key={index} className="classBlock">
                                        <Form inline>
                                            {
                                                classBlock.map(className => {
                                                    return (
                                                        <FormGroup key={className}>
                                                            <ClassLink associateHandler={displayAssociate} dissociateHandler={displayDissociate} name={className}/>
                                                        </FormGroup>
                                                    );
                                                })
                                            }
                                        </Form>
                                    </ListGroup.Item>
                                );
                            })
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
        </Container>
    );
};
