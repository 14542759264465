import React, {useContext} from "react";
import {Button, Nav, Navbar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {PageLink} from "./PageLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../login/UserContext";
import {emptyUser} from "../util/Types";
import {logoutRequest} from "../requests/sessions";
import {hasRole} from "../util/Roles";
import {RoleContext} from "../login/RoleContext";
import {adminRole} from "../util/Globals";

export const TeacherNavbar: React.FC = (): JSX.Element => {
    const userContext = useContext(UserContext);
    const roleContext = useContext(RoleContext);
    const history = useHistory();
    const logout = () => {
        userContext.setUser(emptyUser());
        localStorage.removeItem('token');
        history.push('/login');
        logoutRequest(userContext.user.token)
            .then(result => console.log(result));
    };

    // noinspection HtmlUnknownTarget
    return (
        <Navbar bg="light" expand={"lg"}>
            <Navbar.Brand>
                <img alt="" src="/branding.jpg" height="50px;"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={"basic-navbar-nav"}/>
            <Navbar.Collapse id={"basic-navbar-nav"}>
                <Nav variant={"pills"} className={"mr-auto"}>
                    <PageLink title={"Meine Klasse"} target={"/class/home"}/>
                    <PageLink title={"Alle Klassen"} target={"/classes"}/>

                    { hasRole(roleContext.roles, adminRole) ? <PageLink title={"Nutzer verwalten"} target={"/usermanagement"}/> : '' }
                </Nav>
                <Nav className="justify-content-end">
                    <Navbar.Text className="mr-2"><FontAwesomeIcon icon={faUserAlt}/> {userContext.user.name}</Navbar.Text>
                    <Button variant="primary" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}/> Abmelden</Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
