import React from "react";
import {AssignmentListDisplay} from "../../../util/Types";
import {useHistory} from "react-router-dom";
import {ListGroup, Form} from "react-bootstrap";

interface Props {
    assignment: AssignmentListDisplay,
    showSelect: boolean,
    selected: boolean,
    onToggleSelect?: (assignmentID: string, newValue: boolean) => void
}

export const AssignmentListItem: React.FC<Props> = (props: Props): JSX.Element => {
    const history = useHistory();

    const toggleHandler = () => {
        if (typeof props.onToggleSelect !== 'undefined') {
            props.onToggleSelect(props.assignment.id, !props.selected);
        }
    };

    if (props.showSelect) {
        return (
            <ListGroup.Item action onClick={toggleHandler}>
                <span className="mr-5"/>
                <Form.Check className="float-left mr-3" checked={props.selected} onChange={toggleHandler}/>
                <span className="float-left">{props.assignment.title}</span>
            </ListGroup.Item>
        );
    } else {
        return (
            <ListGroup.Item action onClick={() => history.push('/assignment/' + encodeURI(props.assignment.id))}>
                <span className="mr-5"/>
                <span className="float-left">{props.assignment.title}</span>
            </ListGroup.Item>
        );
    }
};
