import React from "react";
import {noError} from "../util/Globals";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

interface Props {
    error: string|string[]
}

export const ErrorAlert: React.FC<Props> = (props: Props): JSX.Element => {
    if (props.error === noError) {
        return (<div/>);
    } else {
        return (<Alert variant="danger"><FontAwesomeIcon icon={faExclamationCircle}/> {props.error}</Alert>);
    }
};
