import React, {useContext, useEffect, useState} from "react";
import {hasRole} from "../../../util/Roles";
import {RoleContext} from "../../../login/RoleContext";
import {assignmentURL, classTextURL, noError, teacherRole} from "../../../util/Globals";
import {Card} from "react-bootstrap";
import {QuillEditor} from "../../../components/QuillEditor";
import {faEdit, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";
import {IconButton} from "../../../components/IconButton";
import {getAuthenticated, postBodyAuthenticated} from "../../../util/Requests";
import {UserContext} from "../../../login/UserContext";
import {AssignmentListDisplay, AssignmentSummary, ClassTextSummary, ResponseWrapper} from "../../../util/Types";
import {ErrorAlert} from "../../../components/ErrorAlert";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {RenderHTML} from "../../../components/RenderHTML";

interface Props {
    assignmentID: string,
    refresh?: () => void
}

export const AssignmentText: React.FC<Props> = (props: Props): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);
    const [lastEditor, setLastEditor] = useState('');
    const [lastEditTimestamp, setLastEditTimestamp] = useState('');

    const [value, setValue] = useState('');
    const [lastPersistedValue, setLastPersistedValue] = useState('');

    const roleContext = useContext(RoleContext);
    const userContext = useContext(UserContext);

    const getSanitizedHTML = () => {
        // return DOMPurify.sanitize(value) => removed iFrames etc.
        return value;
    };

    const discardChanges = () => {
        setValue(lastPersistedValue);
        setEditing(false);
    };

    const loadAssignmentText = () => {
        if (typeof props.assignmentID === 'undefined' || props.assignmentID.length === 0) {
            console.log('waiting for valid assignmentID');
            return;
        }

        console.log('assignment for text:', props.assignmentID);

        setLoading(true);
        setError(noError);

        const url = encodeURI(assignmentURL + '/' + props.assignmentID);

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;
                console.log(wrapper);

                if (wrapper.error === noError) {
                    console.log('Applying result');
                    const assignmentSummary = wrapper.result as AssignmentSummary;

                    const lastEditDate = new Date(assignmentSummary.updated);
                    const timestamp = lastEditDate.getDate() + '.' + (lastEditDate.getMonth() + 1) + '.' + lastEditDate.getFullYear();

                    setLastEditTimestamp(timestamp);

                    setValue(assignmentSummary.text);
                    setLastPersistedValue(assignmentSummary.text);
                    setLastEditor(assignmentSummary.authorName);
                } else {
                    setError(wrapper.error);
                }
            })
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    const persistChanges = () => {
        setError(noError);
        setLoading(true);
        setLastPersistedValue(value);

        postBodyAuthenticated(assignmentURL + '/text/' + props.assignmentID, userContext.user.token, {text: value})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    // Don't do anything for now
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            })
            // Finally is OK here
            .finally(() => {
                setLoading(false);
                setEditing(false);
            });
    };

    useEffect(loadAssignmentText, [props.assignmentID]);

    if (loading) {
        return <MainContentSpinner/>;
    }

    if (hasRole(roleContext.roles, teacherRole)) {
        if (editing) {
            return (
                <div>
                    <QuillEditor text={value} valueChangeHandler={setValue}/>

                    <VerticalPlaceholder height="0.7em"/>

                    <IconButton loading={loading} icon={faSave} size="sm" variant='success' className="mr-4 float-right" onClick={persistChanges}>Speichern</IconButton>
                    <IconButton loading={loading} icon={faTrashAlt} size="sm" variant='danger' className="mr-4 float-right" onClick={discardChanges}>Verwerfen</IconButton>
                    <VerticalPlaceholder height="0.7em"/>
                    <ErrorAlert error={error}/>
                </div>
            );
        } else {
            return (
                <div>
                    <RenderHTML html={getSanitizedHTML()}/>
                    <VerticalPlaceholder height="0.7em"/>
                    <IconButton loading={loading} icon={faEdit} size="sm" variant='primary' className="mr-4 float-right" onClick={() => setEditing(true)}>Text Bearbeiten</IconButton>
                    <VerticalPlaceholder height="0.7em"/>
                    <ErrorAlert error={error}/>
                </div>
            );
        }
    } else {
        return (
            <div>
                <div className="ignoreParents">
                    { <div className="arial" dangerouslySetInnerHTML={{ __html: getSanitizedHTML() }} /> }
                </div>
                <VerticalPlaceholder height="0.7em"/>
                <ErrorAlert error={error}/>
            </div>
        );
    }
};
