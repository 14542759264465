import React from "react";
import {Button, Card, Container} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {useHistory} from "react-router-dom";

export const NavErrorPage: React.FC = (): JSX.Element => {
    const history = useHistory();
    return (
        <Container>
            <VerticalPlaceholder height="1.5em"/>
            <Card>
                <Card.Body>
                    <Card.Title>Diese Seite konnten wir nicht finden...</Card.Title>
                    <Card.Text>Das tut uns Leid. Vielleicht ist ein Link nicht mehr aktuell?</Card.Text>
                    <VerticalPlaceholder height="2em"/>
                    <Button onClick={history.goBack}>Zurück</Button>
                </Card.Body>
            </Card>
        </Container>
    );
};
