import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Card, Container} from "react-bootstrap";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {EditableTextDisplay} from "../../components/EditableTextDisplay";
import {answerURL, assignmentURL, noError} from "../../util/Globals";
import {MainContentSpinner} from "../../components/MainContentSpinner";
import {emptyAssignment, ResponseWrapper, UserSummary} from "../../util/Types";
import {getAuthenticated, postBodyAuthenticated, postFormAuthenticated} from "../../util/Requests";
import {UserContext} from "../../login/UserContext";
import {IconButton} from "../../components/IconButton";
import {faArrowLeft, faBackward} from "@fortawesome/free-solid-svg-icons";
import {DragAndDrop} from "../../components/DragAndDrop";
import {AssignmentFileList} from "./components/AssignmentFileList";
import {AssignmentText} from "./components/AssignmentText";
import {getSubjectColor} from "../../config/subjects";
import {AnswerLinkList} from "./components/AnswerLinkList";
import {sortUsersAlphabetically} from "../../util/compare";
import {ErrorAlert} from "../../components/ErrorAlert";

interface Props {
    assignmentID: string
}

export const AssignmentPageTeacher: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);
    const [assignment, setAssignment] = useState(emptyAssignment);
    const [answerUsers, setAnswerUsers] = useState([] as UserSummary[]);
    const [feedbackUsers, setFeedbackUsers] = useState([] as string[]);

    const history = useHistory();

    const userContext = useContext(UserContext);

    const addFiles = async (files: File[]) => {
        setLoading(true);

        try {
            const fileObject: any = {};

            files.forEach((file, index) => {
                fileObject['file' + index] = file;
            });

            const url = assignmentURL + '/files/' + props.assignmentID;
            const wrapper = await postFormAuthenticated(url, userContext.user.token, fileObject) as ResponseWrapper;

            if (wrapper.error === noError) {
                setAssignment(wrapper.result);
                setLoading(false);
                loadAssignment();
            } else {
                setError(wrapper.error);
                setLoading(false);
            }
        } catch(e) {
            console.log(e);
            setError('Verbindung zum Server fehlgeschlagen');
            setLoading(false);
        }
    };

    const changeTitle = (newTitle: string) => {
        setLoading(true);

        const url = assignmentURL + '/change/title/' + props.assignmentID;
            postBodyAuthenticated(url, userContext.user.token, {
                title: newTitle
            })
                .then(response => {
                    const wrapper = response as ResponseWrapper;

                    if (wrapper.error === noError) {
                        loadAssignment();
                    } else {
                        setError(wrapper.error);
                        setLoading(false);
                    }
                })
                .catch(reason => {
                    console.log(reason);
                    setError("Server konnte nicht erreicht werden");
                    setLoading(false);
                });
    };

    const loadAnswers = async () => {
        setLoading(true);

        try {
            const url = answerURL + '/list/' + props.assignmentID;
            const wrapper = await getAuthenticated(url, userContext.user.token) as ResponseWrapper;

            if (wrapper.error === noError) {
                setAnswerUsers(sortUsersAlphabetically(wrapper.result.users));
                setFeedbackUsers(wrapper.result.feedbackUsers);
            } else {
                setError(wrapper.error);
            }

            setLoading(false)
        } catch(e) {
            console.log(e);
            setError('Verbindung zum Server fehlgeschlagen');
            setLoading(false);
        }
    };

    const loadAssignment = async () => {
        setLoading(true);

        try {
            const url = assignmentURL + '/' + props.assignmentID;
            const wrapper = await getAuthenticated(url, userContext.user.token) as ResponseWrapper;

            if (wrapper.error === noError) {
                setAssignment(wrapper.result);
                setLoading(false);
            } else {
                setError(wrapper.error);
                setLoading(false);
            }
        } catch(e) {
            console.log(e);
            setError('Verbindung zum Server fehlgeschlagen');
            setLoading(false);
        }
    };

    useEffect(() => {
        loadAssignment();
        loadAnswers();
    }, []);

    if (loading) {
        return <Container>
            <TeacherNavbar/>

            <VerticalPlaceholder height="1em"/>

            <MainContentSpinner/>
        </Container>;
    }

    return (
        <Container>
            <TeacherNavbar/>

            <VerticalPlaceholder height="1em"/>

            <Card>
                <Card.Header>
                    <IconButton onClick={() => {
                        if (assignment.class.length > 0) {
                            history.push('/class/' + encodeURIComponent(assignment.class));
                        }
                    }} variant="outline-dark" icon={faArrowLeft} className="float-left">Zurück</IconButton>
                    <Card.Title><span className="mr-2" style={{backgroundColor: getSubjectColor(assignment.subject)}}>&nbsp;&nbsp;</span><EditableTextDisplay onChange={changeTitle} text={assignment.title}/></Card.Title>
                </Card.Header>
                <Card.Body className="text-left">
                    <AssignmentText assignmentID={assignment.id}/>

                    <VerticalPlaceholder height="1em"/>

                    <Card.Title>Dateien</Card.Title>

                    <VerticalPlaceholder height="1em"/>

                    <AssignmentFileList assignmentID={assignment.id} useSelect={true}/>

                    <DragAndDrop label={"Ziehen Sie Dateien in dieses Feld"} dropLabel={"Dateien hier ablegen"} handleFileTransfer={addFiles}/>

                    <VerticalPlaceholder height="1.5em"/>

                    <Card.Title>Abgaben</Card.Title>

                    <VerticalPlaceholder height="1em"/>

                    <AnswerLinkList assignmentID={props.assignmentID} users={answerUsers} feedbackUsers={feedbackUsers}/>
                </Card.Body>
                <Card.Footer>
                    <ErrorAlert error={error}/>
                </Card.Footer>
            </Card>
            <VerticalPlaceholder height="7em"/>
        </Container>
    );
};
