import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {AuthCheck} from "../login/AuthCheck";
import {ClassPage} from "../pages/class/ClassPage";
import {LoginPage} from "../login/LoginPage";
import {NavErrorPage} from "../pages/navError/NavErrorPage";
import {AuthenticatedRoute} from "./AuthenticatedRoute";
import {ClassListPage} from "../pages/classes/ClassListPage";
import {SubjectPage} from "../pages/subject/SubjectPage";
import {UserManagementPage} from "../pages/userManagement/UserManagementPage";
import {AssignmentPage} from "../pages/assignment/AssignmentPage";
import {AnswerPage} from "../pages/answer/AnswerPage";

export const Routing: React.FC = (): JSX.Element => {
    return (
        <Router>
            <Switch>
                <AuthenticatedRoute exact path='/'>
                    <AuthCheck/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/subject/:className/:subject'>
                    <SubjectPage/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/assignment/:assignment'>
                    <AssignmentPage/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/answer/:assignmentID/:userID/:className?'>
                    <AnswerPage/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/class/:overrideClass?'>
                    <ClassPage/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/classes'>
                    <ClassListPage/>
                </AuthenticatedRoute>

                <AuthenticatedRoute path='/usermanagement'>
                    <UserManagementPage/>
                </AuthenticatedRoute>

                <Route path='/login/:redirect'>
                    <LoginPage/>
                </Route>

                <Route path='/login'>
                    <LoginPage/>
                </Route>

                <Route>
                    <NavErrorPage/>
                </Route>
            </Switch>
        </Router>
    );
};
