import React, {useContext} from "react";
import {Button, Card, Container} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {useHistory} from "react-router-dom";
import {RoleContext} from "../../login/RoleContext";
import {withRouter, RouteComponentProps} from "react-router";
import {Subject} from "../../util/Types";
import {subjects} from "../../config/subjects";
import {NavErrorPage} from "../navError/NavErrorPage";
import {hasRole} from "../../util/Roles";
import {teacherRole} from "../../util/Globals";
import {SubjectPageTeacher} from "./SubjectPageTeacher";
import {SubjectPageStudent} from "./SubjectPageStudent";

interface Props extends RouteComponentProps{
}

const SubjectPageComponent: React.FC<Props> = (props: Props): JSX.Element => {
    const history = useHistory();
    const {roles, setRoles} = useContext(RoleContext);

    // @ts-ignore
    let className = props.match.params.className;

    // @ts-ignore
    let subjectCode = props.match.params.subject;

    let valid = true;

    if (typeof className === 'undefined' || typeof subjectCode === 'undefined') {
        valid = false;
    }

    className = decodeURIComponent(className);
    subjectCode = decodeURIComponent(subjectCode);

    let subject: Subject = {name: 'noMatch', code: subjectCode, color: ''};

    for (let currentSubject of subjects) {
        if (currentSubject.code === subjectCode) {
            subject = currentSubject;
        }
    }

    if (subject.name === 'noMatch') {
        valid = false;
    }

    if (valid) {
        if (hasRole(roles, teacherRole)) {
            return <SubjectPageTeacher className={className} subject={subject}/>;
        } else {
            return <SubjectPageStudent className={className} subject={subject}/>;
        }
    } else {
        return <NavErrorPage/>;
    }


};

export const SubjectPage = withRouter(SubjectPageComponent);
