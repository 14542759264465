import React from "react";
import {makeAnswerFileURL, makeAssignmentFileURL} from "../../../util/Globals";
import {getFileIcon} from "../../../util/icons";
import {ListGroup} from "react-bootstrap";

interface Props {
    assignmentID: string,
    userID: string,
    filename: string
}

export const AnswerFileDisplay: React.FC<Props> = (props: Props): JSX.Element => {
    return <ListGroup.Item action onClick={() => {
        window.open(makeAnswerFileURL(props.assignmentID, props.userID, props.filename));
    }}>
        <span className="float-left mr-5"/>
        <span className={"font-large float-left mr-4 " + getFileIcon(props.filename)}/>
        <span className="float-left">{props.filename}</span>
    </ListGroup.Item>;
};
