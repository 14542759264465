import React from "react";
import {Nav} from "react-bootstrap";
import {useLocation, useHistory} from "react-router-dom";

interface Props {
    title: string,
    target: string
}

export const PageLink: React.FC<Props> = (props): JSX.Element => {
    const location = useLocation();
    const history = useHistory();
    const isActive = location.pathname === props.target;

    const clickHandler = () => {
        history.push(props.target);
    };

    return (
        <Nav.Link onClick={clickHandler} className={ isActive ? 'activeNavLink' : ''}>{props.title}</Nav.Link>
    );
};
