import React, {useRef, useState} from "react";
import {IconButton} from "./IconButton";
import {faCheck, faPen, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FormControl} from "react-bootstrap";

interface Props {
    text: string,
    loading?: boolean,
    onChange?: (newValue: string) => void
}

export const EditableTextDisplay: React.FC<Props> = (props: Props): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState(props.text);

    const inputRef = useRef<HTMLInputElement>(null);

    const save = () => {
        if (props.onChange && inputRef.current) {
            console.log('Change handler fired');
            props.onChange(inputRef.current.value);
        }
        setEditing(false);
    };

    const discard = () => {
        if (inputRef.current) {
            inputRef.current.value = props.text;
        }
        setEditing(false);
    };

    if (editing) {
        return (
            <div className="d-inline-block">
                <FormControl ref={inputRef} className="mb-2" defaultValue={props.text}/>
                <IconButton onClick={save} variant="success" size="sm" className="ml-2" loading={props.loading} icon={faCheck}/>
                <IconButton onClick={discard} variant="danger" size="sm" className="ml-2" loading={props.loading} icon={faTimes}/>
            </div>
        )
    } else {
        return (
            <div className="d-inline-block editable-text">
                {props.text}
                <IconButton onClick={() => setEditing(true)} size="sm" className="ml-2 editable-text-edit" loading={props.loading} icon={faPen}/>
            </div>
        );
    }
};
