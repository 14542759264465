import React, {useContext, useEffect, useState} from "react";
import {withRouter, RouteComponentProps} from "react-router";
import {useHistory} from "react-router-dom";
import {UserContext} from "../../login/UserContext";
import {Card, Container} from "react-bootstrap";
import {AnswerFileList} from "../assignment/components/AnswerFileList";
import {NavErrorPage} from "../navError/NavErrorPage";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {getAuthenticated} from "../../util/Requests";
import {feedbackURL, noError, userURL} from "../../util/Globals";
import {emptyFeedback, ResponseWrapper, UserSummary} from "../../util/Types";
import {MainContentSpinner} from "../../components/MainContentSpinner";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "../../components/IconButton";
import {FeedbackDisplayTeacher} from "../../components/FeedbackDisplayTeacher";

interface Props extends RouteComponentProps{
}

const AnswerPageComponent: React.FC<Props> = (props: Props): JSX.Element => {
    const [user, setUser] = useState<UserSummary>({
        name: 'Unbekannt',
        id: '',
        mail: '',
        created: '',
        updated: ''
    });

    const [feedbackError, setFeedbackError] = useState<string|string[]>(noError);

    const [loading, setLoading] = useState(false);
    const [loadingFeedback, setLoadingFeedback] = useState(false);

    const history = useHistory();

    const userContext = useContext(UserContext);

    // @ts-ignore
    let assignmentID = props.match.params.assignmentID;

    // @ts-ignore
    let userID = props.match.params.userID;

    assignmentID = decodeURIComponent(assignmentID);
    userID = decodeURIComponent(userID);

    const defaultFeedback = emptyFeedback();
    defaultFeedback.assignment = assignmentID;
    defaultFeedback.user = userID;

    const [feedback, setFeedback] = useState(defaultFeedback);

    const goBack = () => {
        history.push('/assignment/' + encodeURIComponent(assignmentID));
    };

    const loadFeedback = () => {
        setLoadingFeedback(true);

        const url = feedbackURL + '/' + assignmentID + '/' + userID;

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setFeedback(wrapper.result);
                } else {
                    setFeedbackError(wrapper.error);
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                setFeedbackError("Server konnte nicht erreicht werden");
                console.log(reason);
            })
            // Finally is OK here
            .finally(() => setLoadingFeedback(false));
    };

    const loadUser = () => {
        setLoading(true);
        getAuthenticated(userURL + '/id/' + userID, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setUser(wrapper.result);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(console.log)
            // finally is OK here
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadUser();
        loadFeedback();
    }, []);

    if (typeof assignmentID === 'undefined' || typeof userID === 'undefined') {
        return <NavErrorPage/>;
    }


    return <Container>
        <TeacherNavbar/>
        <VerticalPlaceholder height="1em"/>
        <Card>
            <Card.Header>
                <IconButton onClick={goBack} variant="outline-dark" icon={faArrowLeft} className="float-left">Zurück</IconButton>
                {loading ? <MainContentSpinner/> : <Card.Title>Abgegebene Dateien von {user.name}</Card.Title>}
            </Card.Header>
            <Card.Body>
                <AnswerFileList assignmentID={assignmentID} userID={userID}/>
                <VerticalPlaceholder height="1em"/>
                <FeedbackDisplayTeacher feedback={feedback} refresh={loadFeedback}/>
            </Card.Body>
        </Card>
    </Container>
};

export const AnswerPage = withRouter(AnswerPageComponent);
