import React from "react";
import {feedbackSummary} from "../util/Types";
import {Card, ListGroup} from "react-bootstrap";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {RenderHTML} from "./RenderHTML";
import {makeFeedbackFileURL} from "../util/Globals";
import {getFileIcon} from "../util/icons";

interface Props {
    feedback: feedbackSummary
}

export const FeedbackDisplay: React.FC<Props> = (props: Props): JSX.Element => {
    const hasText = props.feedback.text && props.feedback.text.length > 0;
    const hasFiles = props.feedback.files && props.feedback.files.length > 0;

    if (!hasText && !hasFiles) {
        return <span/>;
    }

    return <div>
            <Card.Title>Rückmeldung deines Lehrers</Card.Title>
            {
                hasText ? <div>
                    <VerticalPlaceholder height="0.5em"/>
                    <RenderHTML html={props.feedback.text}/>
                </div> : <span/>
            }
            {
                hasFiles ? <div>
                    <VerticalPlaceholder height="0.5em"/>
                    <ListGroup variant="flush">
                        {
                            props.feedback.files!.map(file => {
                                return <ListGroup.Item key={file} action onClick={() => {
                                    window.open(makeFeedbackFileURL(props.feedback.assignment, props.feedback.user, file))
                                }}>
                                    <span className={"mr-5"}/>
                                    <span className={"font-large float-left mr-4 " + getFileIcon(file)}/>
                                    <span className={"float-left"}>{file}</span>
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div> : <span/>
            }
        </div>;
};
