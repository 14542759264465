import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faStar} from "@fortawesome/free-solid-svg-icons";
import {ClassNameContext} from "../../../login/ClassNameContext";
import {FormControl, InputGroup} from "react-bootstrap";

interface Props {
    name: string,
    associateHandler: (className: string) => void,
    dissociateHandler: () => void
}

export const ClassLink: React.FC<Props> = (props: Props): JSX.Element => {
    const classNameContext = useContext(ClassNameContext);

    const history = useHistory();

    const active = props.name === classNameContext.className;
    const starClass = active ? 'favoriteIconActive' : 'favoriteIcon';

    const toggleAssociation = () => {
        if (active) {
            props.dissociateHandler();
        } else {
            props.associateHandler(props.name);
        }
    };

    const openClassPage = () => {
      history.push('/class/' + encodeURIComponent(props.name));
    };

    return (
        <InputGroup className="classLink mb-3 mr-3">
            <InputGroup.Prepend onClick={toggleAssociation} className={" " + starClass}>
                <InputGroup.Text className="parentColor classPrepend">
                    <FontAwesomeIcon icon={faStar}/>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl disabled placeholder={props.name} className="classFakeInput" onClick={openClassPage}/>
            <InputGroup.Append onClick={openClassPage}>
                <InputGroup.Text className="classAppend">
                    <FontAwesomeIcon icon={faChevronRight}/>
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};
