import React, {useContext} from "react";
import {withRouter, RouteComponentProps} from "react-router";
import {hasRole} from "../../util/Roles";
import {teacherRole} from "../../util/Globals";
import {ClassPageTeacher} from "./ClassPageTeacher";
import {ClassPageStudent} from "./ClassPageStudent";
import {RoleContext} from "../../login/RoleContext";
import {ClassNameContext} from "../../login/ClassNameContext";
import {NoClassAssignedErrorPage} from "../noClassAssignedError/NoClassAssignedErrorPage";
import {parseClassName} from "../../config/preferences";

interface Props extends RouteComponentProps{

}

const ClassPageComponent: React.FC<Props> = (props: Props): JSX.Element => {
    const {roles, setRoles} = useContext(RoleContext);
    const {className, setClassName} = useContext(ClassNameContext);

    let finalClass = className; // Class name can be determined by URI param or default
    console.log('Params: ', props.match.params);

    // @ts-ignore
    const override = props.match.params.overrideClass;

    if (typeof override !== 'undefined' && override !== 'home') {
        finalClass = decodeURIComponent(override);
    }

    finalClass = parseClassName(finalClass);

    console.log("Final class: ", finalClass);

    if (finalClass.length > 0) {
        if (hasRole(roles, teacherRole)) {
            return <ClassPageTeacher className={finalClass}/>;
        } else {
            return <ClassPageStudent className={finalClass}/>;
        }
    } else {
        return <NoClassAssignedErrorPage/>;
    }
};

export const ClassPage = withRouter(ClassPageComponent);
