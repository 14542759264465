import React, {useContext, useEffect, useState} from "react";
import {AssignmentListDisplay, ResponseWrapper} from "../../../util/Types";
import {Card, ListGroup, Spinner, Form} from "react-bootstrap";
import {AssignmentListItem} from "./AssignmentListItem";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {IconButton} from "../../../components/IconButton";
import {faList, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {deleteBodyAuthenticated} from "../../../util/Requests";
import {assignmentURL, noError} from "../../../util/Globals";
import {UserContext} from "../../../login/UserContext";
import {ConfirmationPopup} from "../../../components/ConfirmationPopup";

interface Props {
    assignments: AssignmentListDisplay[],
    loading: boolean,
    refresh?: () => void,
    useSelect: boolean
}

export const AssignmentList: React.FC<Props> = (props: Props): JSX.Element => {
    const [selectedAssignments, setSelectedAssignments] = useState([] as string[]);
    const [showSelect, setShowSelect] = useState(false);

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const userContext = useContext(UserContext);

    const setSelected = (assignmentID: string, value: boolean) => {
        if (value) {
            if (isSelected(assignmentID)) {
                return;
            } else {
                const newAssignments = selectedAssignments.slice();
                newAssignments.push(assignmentID);
                console.log(newAssignments);
                setSelectedAssignments(newAssignments);
            }
        } else {
            if (isSelected(assignmentID)) {
                const newAssignments = selectedAssignments.slice();
                newAssignments.splice(newAssignments.indexOf(assignmentID), 1);
                console.log(newAssignments);
                setSelectedAssignments(newAssignments);
            } else {
                return;
            }
        }
    };

    const enableSelect = () => {
        if (props.useSelect) {
            setShowSelect(true);
        }
    };

    const allSelected = () => {
        return selectedAssignments.length === props.assignments.length;
    };

    const isSelected = (id: string): boolean => {
        return selectedAssignments.includes(id);
    };

    const deleteAssignments = () => {
        if (selectedAssignments.length === 0) {
            // Nothing to delete
            return;
        }

        deleteBodyAuthenticated(assignmentURL + '/multiple', userContext.user.token, {
            assignments: selectedAssignments
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    if (typeof props.refresh !== 'undefined') {
                        props.refresh();
                    }
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(console.log)
            .finally(() => {
                setDeleteConfirmation(false);
                setShowSelect(false);
                setSelectedAssignments([]);
            });
    };

    const toggleSelectAll = () => {
        console.log('toggle select all');
        if (allSelected()) {
            console.log('Unselect all');
            setSelectedAssignments([]);
        } else {
            console.log('Select all');
            const newAssignments = props.assignments.map(assignment => {
                return assignment.id
            });
            console.log(newAssignments);
            setSelectedAssignments(newAssignments);
        }

    };

    if (props.loading) {
        return <MainContentSpinner/>;
    }

    if (props.assignments.length === 0) {
        return <Card.Text>Für dieses Fach gibt es noch keine Aufgaben!</Card.Text>;
    }

    if (!props.useSelect) {
        return (
            <ListGroup variant="flush">
                {
                    props.assignments.map(assignment => {
                        return <AssignmentListItem key={assignment.id} onToggleSelect={setSelected} showSelect={showSelect}
                                                   selected={isSelected(assignment.id)}
                                                   assignment={assignment}/>;
                    })
                }
            </ListGroup>
        )
    }

    return (
        <ListGroup variant="flush">
            <ConfirmationPopup text={"Sollen diese " + selectedAssignments.length + " Aufgaben wirklich gelöscht werden? Alle Abgaben zu diesen Aufgaben werden dann ebenfalls gelöscht!"}
                                show={deleteConfirmation}
                                onConfirm={deleteAssignments}
                                onCancel={() => {
                                    setDeleteConfirmation(false);
                                    setSelectedAssignments([]);
                                }}
            />
            {
                props.assignments.map(assignment => {
                    return <AssignmentListItem key={assignment.id} onToggleSelect={setSelected} showSelect={showSelect}
                                               selected={isSelected(assignment.id)}
                                               assignment={assignment}/>;
                })
            }


            {
                showSelect
                    ? <ListGroup.Item key="buttons" className="align-items-start">
                        <span className="mr-5"/>
                        <Form.Check checked={allSelected()} onChange={toggleSelectAll} className="float-left mr-3"/>
                        <IconButton onClick={() => {
                            setSelectedAssignments([]);
                            setShowSelect(false);
                        }} className="float-left mr-3" size="sm" icon={faTimes}>Abbrechen</IconButton>
                        <IconButton onClick={() => setDeleteConfirmation(true)} className="float-left mr-3" variant="danger" size="sm" icon={faTrashAlt}>Ausgewählte Löschen</IconButton>
                    </ListGroup.Item>
                    : <ListGroup.Item key="buttons" className="align-items-start">
                        <IconButton onClick={enableSelect} className="float-left mr-3" size="sm" icon={faList}>Auswählen</IconButton>
                    </ListGroup.Item>
            }
        </ListGroup>
    );
};
