import React, {useContext, useEffect, useRef, useState} from "react";
import {Alert, Card, Col, Form, FormControl, ListGroup, Row} from "react-bootstrap";
import {
    associationsURL,
    changePasswordURL,
    changeUsernameURL,
    getSimplePasswordURL,
    noError, studentRole,
    userByRoleURL, userJobURL,
    userURL
} from "../../../util/Globals";
import {ResponseWrapper, UserSummary} from "../../../util/Types";
import {UserContext} from "../../../login/UserContext";
import {deleteBodyAuthenticated, getAuthenticated, postBodyAuthenticated} from "../../../util/Requests";
import {EditableTextDisplay} from "../../../components/EditableTextDisplay";
import {IconButton} from "../../../components/IconButton";
import {faKey, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {ConfirmationPopup} from "../../../components/ConfirmationPopup";
import {InfoPopup} from "../../../components/InfoPopup";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {ErrorAlert} from "../../../components/ErrorAlert";
import {sortUsersAlphabetically} from "../../../util/compare";

interface Props {
    triggerReload?: () => void
}

export const StudentManager: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPasswordInfo, setShowPasswordInfo] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // ID of the user whos password should be regenerated
    const [passwordUser, setPasswordUser] = useState('');
    const [deleteUser, setDeleteUser] = useState<UserSummary|null>(null);
    const [generatedPassword, setGeneratedPassword] = useState('');

    const [students, setStudents] = useState([] as UserSummary[]);
    const userContext = useContext(UserContext);

    const firstNameInput = useRef<HTMLInputElement>(null);
    const lastNameInput = useRef<HTMLInputElement>(null);
    const classInput = useRef<HTMLInputElement>(null);

    const loadStudents = () => {
        setLoading(true);
        setError(noError);

        const url = userByRoleURL + '/exclusive/' + studentRole + '/with-associations';

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setStudents(sortUsersAlphabetically(wrapper.result));
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Server konnte nicht erreicht werden, bitte versuchen Sie es noch einmal')
            })
            .finally(() => setLoading(false));
    };

    const promptDelete = (user: UserSummary) => {
        setDeleteUser(user);
        setShowDeleteConfirmation(true);
    };

    const executeDelete = () => {
        if (!deleteUser) {
            setShowDeleteConfirmation(false);
            setError('Kein Benutzer ausgewählt');
            return;
        }

        setLoading(true);
        setError(noError);

        deleteBodyAuthenticated(userURL, userContext.user.token, {
            user: deleteUser.id
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadStudents();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }

                setShowDeleteConfirmation(false);
            })
            .catch(reason => {
                setLoading(false);
                setError("Server konnte nicht erreicht werden");
                console.log(reason);
            })
            .finally(() => setShowDeleteConfirmation(false))
    };

    const changeUsername = (userID: string, newName: string) => {
        setLoading(true);
        setError(noError);

        postBodyAuthenticated(changeUsernameURL, userContext.user.token, {
            user: userID,
            name: newName
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error !== noError) {
                    setError(wrapper.error);
                } else {
                    loadStudents();
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Server konnte nicht erreicht werden. Bitte versuchen Sie es erneut');
            })
            .finally(() => setLoading(false));
    };

    const getSimplePassword = async (): Promise<string|null> => {
        return new Promise((resolve) => {
            getAuthenticated(getSimplePasswordURL, userContext.user.token)
                .then(response => {
                    const wrapper = response as ResponseWrapper;

                    if (wrapper.error === noError) {
                        resolve(wrapper.result);
                    } else {
                        resolve(null);
                    }
                })
                .catch(() => resolve(null));
        });
    };

    const changePassword = async () => {
        setLoading(true);
        setShowConfirmPassword(false);
        setError(noError);
        const password = await getSimplePassword();

        if (!password) {
            setError('Konnte kein neues Passwort generieren');
            setLoading(false);
            return;
        }

        setGeneratedPassword(password);

        postBodyAuthenticated(changePasswordURL, userContext.user.token, {
            user: passwordUser,
            password
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    loadStudents();
                    setShowPasswordInfo(true);
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Server konnte nicht erreicht werden. Bitte versuchen Sie es erneut');
                setLoading(false)
            });
    };

    const changeClass = (userID: string, newClass: string) => {
        setLoading(true);

        postBodyAuthenticated(associationsURL, userContext.user.token, {user: userID, key: 'class', value: newClass})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadStudents();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Verbindung zum Server fehlgeschlagen');
                setLoading(false);
            });
    };

    const userElement = (user: UserSummary) => {
        if (user.name === "root") {
            return <span key={user.id}/>;
        }

        return (
            <ListGroup.Item key={user.id}>
                <Row>
                    <Col>
                        <EditableTextDisplay text={user.name} onChange={newValue => changeUsername(user.id, newValue)}/>
                    </Col>
                    <Col>
                        <EditableTextDisplay text={(user.associations && user.associations.class) ? user.associations.class : ''} onChange={newValue => changeClass(user.id, newValue)}/>
                    </Col>
                    <Col>
                        <IconButton onClick={() => {
                            setPasswordUser(user.id);
                            setShowConfirmPassword(true);
                        }} loading={loading} size="sm" icon={faKey} variant="outline-dark">Passwort vergessen?</IconButton>
                    </Col>
                    <Col>
                        <IconButton onClick={() => promptDelete(user)} loading={loading} icon={faTrashAlt} variant="outline-danger">Löschen</IconButton>
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    };

    const userElementList = () => {
        return students.map(student => userElement(student));
    };

    const createTeacher = () => {
        if (!classInput.current || !lastNameInput.current || !firstNameInput.current) {
            return;
        }

        setError(noError);
        setLoading(true);

        const lastName = lastNameInput.current.value;
        const firstName = firstNameInput.current.value;
        const className = classInput.current.value;

        postBodyAuthenticated(userJobURL, userContext.user.token, {
            type: 'createStudents',
            data: [[lastName, firstName, className]]
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    if (props.triggerReload) {
                        props.triggerReload();
                    }
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Server konnte nicht erreicht werden');
            })
            .finally(() => setLoading(false));
    };

    useEffect(loadStudents, []);

    return (
        <Card>
            <ConfirmationPopup
                text="Aus Sicherheitsgründen werden Passwörter nicht unverschlüsselt gespeichert. Wir können aber ein neues Passwort generieren. Soll ein neues Passwort generiert werden?"
                show={showConfirmPassword}
                onConfirm={changePassword} onCancel={() => setShowConfirmPassword(false)}
            />
            <ConfirmationPopup
                text={"Wollen Sie den Benutzer '" + (deleteUser ? deleteUser.name : 'Unbekannter Benutzer') + "' wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"}
                show={showDeleteConfirmation} onConfirm={executeDelete} onCancel={() => setShowDeleteConfirmation(false)}
            />
            <InfoPopup text={"Neues Passwort: " + generatedPassword} show={showPasswordInfo} onClose={() => setShowPasswordInfo(false)}/>
            <Card.Header>
                <Form inline>
                    <FormControl className="mr-3" ref={firstNameInput} placeholder="Vorname"/>
                    <FormControl className="mr-3" ref={lastNameInput} placeholder="Name"/>
                    <FormControl className="mr-3" ref={classInput} placeholder="Klasse"/>
                    <IconButton icon={faPlus} onClick={createTeacher} variant="success" loading={loading}>Anlegen</IconButton>
                </Form>
            </Card.Header>
            <Card.Body>
                <ListGroup>
                    { loading ? <MainContentSpinner/> : userElementList() }
                </ListGroup>
            </Card.Body>
            <Card.Footer>
                { error ? <ErrorAlert error={error}/> : '' }
            </Card.Footer>
        </Card>
    );
};
