import React, {ChangeEvent, Ref, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "react-bootstrap";
import {scheduleName} from "../config/preferences";

interface Props {
    handleFileTransfer?: (files: File[]) => void
    multiple?: boolean,
    small?: boolean
}

export const CustomFileInputSchedule: React.FC<Props> = (props: Props): JSX.Element => {
    const inputRef: Ref<HTMLInputElement> = useRef(null);

    const changeHandler = (event?: ChangeEvent) => {
        if (props.handleFileTransfer) {
            if (inputRef && inputRef.current && inputRef.current.files) {
                const fileList: FileList = inputRef.current.files;

                const files = [];
                for (let i = 0; i < fileList.length; ++i) {
                    files.push(fileList.item(i)!);
                }

                props.handleFileTransfer(files);
            }
        } else {
            console.log('File Input: No handler attached');
        }
    };

    return (
      <label className={props.small ? "scheduleUploadLabel" : 'scheduleUploadLabelFullWidth'}>
          <input ref={inputRef} onChange={changeHandler} type="file" multiple={props.multiple} style={{display: "none"}}/>
          <Alert className="scheduleUploadField"><FontAwesomeIcon icon={faUpload} className="mr-3"/>PDF-Datei als {scheduleName} auswählen</Alert>
      </label>
    );
};
