import React from "react";
import {UserSummary} from "../../../util/Types";
import {ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {faCheckCircle, faUserAlt} from "@fortawesome/free-solid-svg-icons";

interface Props {
    assignmentID: string,
    users: UserSummary[],
    feedbackUsers: string[],
    className?: string
}

export const AnswerLinkList: React.FC<Props> = (props: Props): JSX.Element => {
    const history = useHistory();
    if (props.users.length === 0) {
        return <div>Bisher hat noch kein Schüler etwas abgegeben.</div>
    }

    const open = (userID: string) => {
        history.push('/answer/' + encodeURIComponent(props.assignmentID) + '/' + encodeURIComponent(userID) + (props.className ? '/' + encodeURIComponent(props.className) : ''));
    };

    return <ListGroup>
        {
            props.users.map(user => {
                return <ListGroup.Item key={user.id} action onClick={() => open(user.id)}>
                    <FontAwesomeIcon icon={faUserAlt}/><span className="ml-3">{user.name}</span>
                    {
                        props.feedbackUsers.includes(user.id) ? <OverlayTrigger placement={'top'} key={user.id} overlay={
                                <Tooltip id={'tooltip-' + user.id}>Für diese Abgabe wurde eine Rückmeldung eingetragen</Tooltip>
                            }>
                            <FontAwesomeIcon className="float-right" style={{color: "#004400"}} icon={faCheckCircle}/>
                        </OverlayTrigger>
                            : <span/>
                    }
                </ListGroup.Item>;
            })
        }
    </ListGroup>;
};
