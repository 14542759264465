import React, {useContext, useState} from "react";
import {UserContext} from "../../../login/UserContext";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";
import {Tab, Tabs} from "react-bootstrap";
import {DragAndDrop} from "../../../components/DragAndDrop";
import {postBodyAuthenticated} from "../../../util/Requests";
import {noError, userJobURL} from "../../../util/Globals";
import {ErrorAlert} from "../../../components/ErrorAlert";
import {ResponseWrapper} from "../../../util/Types";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {TeacherManager} from "./TeacherManager";
import {StudentManager} from "./StudentManager";

interface Props {
    triggerReload?: () => void
}

export const UserJobCreator: React.FC<Props> = (props: Props): JSX.Element => {
    const [activeTab, setActiveTab] = useState('manageStudents');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);
    const userContext = useContext(UserContext);

    const sendJob = async (files: File[]) => {
        const data: string[][] = await csvToArray(files);

        postBodyAuthenticated(userJobURL, userContext.user.token, {
            type: activeTab,
            data
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    if (props.triggerReload) {
                        props.triggerReload();
                    }
                } else {
                    setLoading(false);
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
                setLoading(false);
                setError('Server konnte nicht erreicht werden');
            });
    };

    const csvToArray = async (files: File[]) => {
        // The CSVs we use have the Format 'firstname;lastname;class' / 'title;firstname;lastname' per row
        const rows: string[][] = [];

        for (let file of files) {
            const text = await file.text();
            const fileRows = text.split('\n');

            for (let fileRow of fileRows) {
                if (fileRow.trim().length === 0) {
                    continue;
                }
                rows.push(fileRow.split(';'));
            }
        }

        return rows;
    };

    const handleDrop = (files: File[]) => {
        console.log('File dropped');
        setLoading(true);
        sendJob(files);
    };

    if (error !== noError) {
        return <ErrorAlert error={error}/>;
    }

    if (loading) {
        return <MainContentSpinner/>;
    }

    return (
        <div>
            Keine Aktion in Arbeit.
            <VerticalPlaceholder height="1em"/>
            <Tabs activeKey={activeTab} onSelect={tabName => {
                if (tabName) {
                    setActiveTab(tabName)
                }
            }}>
                <Tab eventKey="manageStudents" title="Schüler verwalten">
                    <StudentManager triggerReload={props.triggerReload}/>
                </Tab>
                <Tab eventKey="manageTeachers" title="Lehrer verwalten">
                    <TeacherManager triggerReload={props.triggerReload}/>
                </Tab>
                <Tab eventKey="createStudents" title="Schüler anlegen">
                    <VerticalPlaceholder height="1.5em"/>
                    Erstellen Sie eine Liste von Schülern mit einer CSV-Datei aus dem Schulverwaltungsprogramm.
                    <br/>
                    <a href="https://grusl.de" target="_blank">Wie exportiere ich die CSV-Datei aus dem Schulverwaltungsprogramm?</a>
                    <VerticalPlaceholder height="1.5em"/>
                    <DragAndDrop label={'Ziehen Sie eine CSV-Datei in dieses Feld'} dropLabel={'CSV-Datei hier ablegen'} handleFileTransfer={handleDrop}/>
                </Tab>
                <Tab eventKey="createTeachers" title="Lehrer anlegen">
                    <VerticalPlaceholder height="1.5em"/>
                    Erstellen Sie eine Liste von Lehrkräften mit einer CSV-Datei aus dem Schulverwaltungsprogramm.
                    <br/>
                    <a href="https://grusl.de" target="_blank">Wie exportiere ich die CSV-Datei aus dem Schulverwaltungsprogramm?</a>
                    <VerticalPlaceholder height="1.5em"/>
                    <DragAndDrop label={'Ziehen Sie eine CSV-Datei in dieses Feld'} dropLabel={'CSV-Datei hier ablegen'} handleFileTransfer={handleDrop}/>
                </Tab>
            </Tabs>
        </div>
    );
};
