import React, {useContext, useEffect, useState} from "react";
import {Card, Container} from "react-bootstrap";
import {StudentNavbar} from "../../navigation/StudentNavbar";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {IconButton} from "../../components/IconButton";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {getSubjectColor} from "../../config/subjects";
import {EditableTextDisplay} from "../../components/EditableTextDisplay";
import {AssignmentText} from "./components/AssignmentText";
import {AssignmentFileList} from "./components/AssignmentFileList";
import {DragAndDrop} from "../../components/DragAndDrop";
import {UserContext} from "../../login/UserContext";
import {answerURL, assignmentURL, feedbackURL, noError} from "../../util/Globals";
import {getAuthenticated, postFormAuthenticated} from "../../util/Requests";
import {emptyAssignment, emptyFeedback, ResponseWrapper} from "../../util/Types";
import {useHistory} from "react-router";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {MainContentSpinner} from "../../components/MainContentSpinner";
import {AnswerFileList} from "./components/AnswerFileList";
import {FeedbackDisplay} from "../../components/FeedbackDisplay";

interface Props {
    assignmentID: string
}

export const AssignmentPageStudent: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string|string[]>(noError);
    const [assignment, setAssignment] = useState(emptyAssignment);
    const [feedback, setFeedback] = useState(emptyFeedback());
    const [loadingFeedback, setLoadingFeedback] = useState(false);

    const history = useHistory();

    const userContext = useContext(UserContext);

    const loadFeedback = () => {
        setLoadingFeedback(true);

        const url = feedbackURL + '/' + props.assignmentID + '/self';

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setFeedback(wrapper.result);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            })
            // Finally is OK here
            .finally(() => setLoadingFeedback(false));
    };

    const loadAssignment = async () => {
        setLoading(true);

        try {
            const url = assignmentURL + '/' + props.assignmentID;
            const wrapper = await getAuthenticated(url, userContext.user.token) as ResponseWrapper;

            if (wrapper.error === noError) {
                setAssignment(wrapper.result);
                setLoading(false);
            } else {
                setError(wrapper.error);
                setLoading(false);
            }
        } catch(e) {
            console.log(e);
            setError('Verbindung zum Server fehlgeschlagen');
            setLoading(false);
        }
    };

    const uploadAnswer = async (files: File[]) => {
        setLoading(true);

        try {
            const fileObject: any = {};

            files.forEach((file, index) => {
                fileObject['file' + index] = file;
            });

            const url = answerURL + '/files/' + props.assignmentID;
            const wrapper = await postFormAuthenticated(url, userContext.user.token, fileObject) as ResponseWrapper;

            if (wrapper.error === noError) {
                setAssignment(wrapper.result);
                setLoading(false);
                loadAssignment();
            } else {
                setError(wrapper.error);
                setLoading(false);
            }
        } catch(e) {
            console.log(e);
            setError('Verbindung zum Server fehlgeschlagen');
            setLoading(false);
        }
    };

    useEffect(() => {
        loadAssignment();
        loadFeedback();
    }, [props.assignmentID]);

    if (loading) {
        return <Container>
            <TeacherNavbar/>

            <VerticalPlaceholder height="1em"/>

            <MainContentSpinner/>
        </Container>;
    }

    return (
        <Container>
            <StudentNavbar/>

            <VerticalPlaceholder height="1em"/>

            <Card>
                <Card.Header>
                    <IconButton onClick={history.goBack} variant="outline-dark" icon={faArrowLeft} className="float-left">Zurück</IconButton>
                    <Card.Title><span className="mr-2" style={{backgroundColor: getSubjectColor(assignment.subject)}}>&nbsp;&nbsp;</span>{assignment.title}</Card.Title>
                </Card.Header>
                <Card.Body className="text-left">
                    <AssignmentText assignmentID={assignment.id}/>

                    <VerticalPlaceholder height="1em"/>

                    <Card.Title>Dateien</Card.Title>

                    <VerticalPlaceholder height="1em"/>

                    <AssignmentFileList assignmentID={assignment.id} useSelect={false}/>

                    <VerticalPlaceholder height="1em"/>

                    <FeedbackDisplay feedback={feedback}/>

                    <VerticalPlaceholder height="1em"/>

                    <Card.Title>Deine Abgaben</Card.Title>

                    <VerticalPlaceholder height="1em"/>
                    
                    <AnswerFileList assignmentID={assignment.id} userID={'self'}/>

                    <VerticalPlaceholder height="1.5em"/>

                    <DragAndDrop handleFileTransfer={uploadAnswer} label={'Zieh Dateien in dieses Feld, um sie abzugeben'} dropLabel={'Dateien hier ablegen'}/>
                </Card.Body>
            </Card>
        </Container>
    );
};
