import React from "react";

interface Props {
    html: string
}

export const RenderHTML: React.FC<Props> = (props: Props): JSX.Element => {
    return <div className="ignoreParents">
        { <div className="arial" dangerouslySetInnerHTML={{ __html: props.html }} /> }
    </div>
};
