import React, {useContext, useEffect, useState} from "react";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {ListGroup} from "react-bootstrap";
import {AnswerFileDisplay} from "./AnswerFileDisplay";
import {getAuthenticated} from "../../../util/Requests";
import {answerURL, noError} from "../../../util/Globals";
import {UserContext} from "../../../login/UserContext";
import {ResponseWrapper} from "../../../util/Types";

interface Props {
    assignmentID: string,
    userID: string
}

export const AnswerFileList: React.FC<Props> = (props: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([] as string[]);

    const userContext = useContext(UserContext);

    const loadFiles = () => {
        setLoading(true);

        getAuthenticated(answerURL + '/files/' + props.assignmentID + '/' + props.userID, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setFiles(wrapper.result);
                } else {
                    setFiles([]);
                    console.log(wrapper.error);
                }

                setLoading(false);
            })
            .catch(reason => {
                console.log(reason);
                setLoading(false);
            });
    };

    useEffect(loadFiles, []);

    if (loading) {
        return <MainContentSpinner/>;
    }

    if (files.length === 0) {
        return <span/>;
    }

    return (
        <ListGroup>
            {
                files.map(file => <AnswerFileDisplay key={file} assignmentID={props.assignmentID} userID={props.userID} filename={file}/>)
            }
        </ListGroup>
    )
};
