import React from "react";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonProps} from "react-bootstrap/Button";

interface Props extends ButtonProps {
    icon: IconDefinition,
    loading?: boolean
}

export const IconButton: React.FC<Props> = (props: Props): JSX.Element => {
    const {icon, loading, ...buttonProps} = props;

    if (props.loading) {
        return (
          <Button {...buttonProps} disabled>
              <Spinner className={props.children ? "mr-2" : ""} animation="border" role="status" size="sm"/>
              { props.children }
          </Button>
        );
    } else {
        return (
            <Button {...buttonProps}>
                <FontAwesomeIcon icon={props.icon} className={props.children ? "mr-2" : ""}/>
                { props.children }
            </Button>
        );
    }
};
