import {noError} from "./Globals";

export type User = {
    name: string,
    token: string,
    loggedIn: boolean
};

export type ResponseWrapper = {
    result: any,
    error: string | string[]
}

export type Subject = {
    name: string,
    color: string,
    code: string
}

export type UserSummary = {
    id: string,
    name: string,
    mail: string|null,
    created: string,
    updated: string,
    associations?: any
}

export const emptyUser = (): User => {
    return {
        name: 'None',
        token: '',
        loggedIn: false
    };
};

export type UserRoleSummary = {
    id: string,
    roleID: string,
    roleName: string
};

export type AssociationSummary = {
    key: string,
    value: string
};

export type ClassTextSummary = {
    id: string,
    class: string,
    created: string,
    updated: string,
    lastEditor: string,
    text: string
}

export type AssignmentListDisplay = {
    id: string,
    title: string,
    authorName: string,
    updated: string
};

export type AssignmentSummary = {
    id: string,
    title: string,
    authorName: string,
    text: string,
    subject: string,
    class: string,
    updated: string
}

export const emptyAssignment: AssignmentSummary = {
    id: '',
    title: '',
    authorName: '',
    text: '',
    updated: '',
    subject: '',
    class: ''
};

export type UserManagementJob = {
    type: string,
    id: string,
    hasJob: boolean,
    running: boolean,
    error: string,
    data: any[],
    completed: any[],
    working: boolean
};

export const emptyUserManagementJob: UserManagementJob = {
    type: 'none',
    id: '',
    hasJob: false,
    running: false,
    error: noError,
    data: [],
    completed: [],
    working: false
};

export type userDetails = {
    name: string,
    username: string,
    password: string,
    className?: string
};

export type feedbackSummary = {
    id: string,
    assignment: string,
    user: string,
    text: string,
    updated: string,
    authorName: string,
    files?: string[]
};

export const emptyFeedback = () => {
    return {
        id: '',
        assignment: '',
        user: '',
        text: '',
        updated: '',
        authorName: '',
        files: []
    };
};
